import { PureComponent } from "react";
import { connect } from "react-redux";
import withRouter from "../global/withRouter";

import { withCookies } from "react-cookie";

import { sendCode, verifyEmail } from "../../data/user/userActions";

const mapStateToProps = (state, ownProps) => {
  const user = state.user.user;
  if (user && user.accessToken && user.type > 0 && user.verified) {
    setTimeout(() => {
      ownProps.router.navigate("/home/");
    }, 10);
    return {
      redirect: true,
    };
  }

  if (user && user.accessToken && user.type === 0 && user.verified) {
    setTimeout(() => {
      ownProps.router.navigate("/signin/details/");
    }, 10);
    return {
      redirect: true,
    };
  }
  const { code } = ownProps.match.params;
  let email = user.email;

  if (!email || email.length < 4) {
    email = state.temp.email;
  }

  if (!email || email.length < 4) {
    email = ownProps.cookies.get("veto:email");
  }

  if (!email || email.length < 4) {
    const dec = window.atob(code);
    email = dec.split(":")[0];
  }

  return {
    code,
    email,
    user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendCode: (data) => dispatch(sendCode(data)),
    verifyEmail: (code) => dispatch(verifyEmail(code)),
  };
};

class SignInWithCode extends PureComponent {
  state = {};

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.email && nextProps.code) {
      nextProps.verifyEmail(nextProps.code);
    } else if (nextProps.user && nextProps.user.email && nextProps.code) {
      nextProps.verifyEmail(nextProps.code);
    }
    return null;
  }

  componentDidMount() {}

  render() {
    return null;
  }
}

export default withCookies(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(SignInWithCode)),
);
