import { connect } from "react-redux";

import withRouter from "../global/withRouter";

import EnterCodeView from "./EnterCodeView";
import { sendCode, logOut } from "../../data/user/userActions";

const mapStateToProps = (state, ownProps) => {
  const user = state.user.user;
  if (user && user.accessToken && user.name && user.name.length > 4) {
    setTimeout(() => {
      ownProps.router.navigate("/home/");
    }, 10);
    return {
      redirect: true,
    };
  }

  if (user && user.accessToken && (!user.name || user.name.length === 0)) {
    setTimeout(() => {
      ownProps.router.navigate("/signin/details/");
    }, 10);
    return {
      redirect: true,
    };
  }

  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendCode: (data) => dispatch(sendCode(data)),
    logOut: () => dispatch(logOut()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EnterCodeView),
);
