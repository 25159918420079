import { connect } from "react-redux";

import withRouter from "../global/withRouter";

import DetailsView from "./DetailsView";
import { sendDetails } from "../../data/user/userActions";

const mapStateToProps = (state, ownProps) => {
  const user = state.user.user;
  console.log({ user });
  if (
    user &&
    user.email &&
    user.accessToken &&
    user.name &&
    user.name.length > 4 &&
    user.phone
  ) {
    setTimeout(() => {
      ownProps.router.navigate("/home/");
    }, 10);
    return {
      redirect: true,
    };
  }

  return { user };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendDetails: (data) => dispatch(sendDetails(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DetailsView),
);
