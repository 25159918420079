import { connect } from "react-redux";
import withRouter from "../withRouter";

import LoaderView from "./LoaderView";

const mapStateToProps = (state) => {
  return {
    loading: state.app.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoaderView),
);
