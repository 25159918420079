import { applyMiddleware, createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { thunk } from "redux-thunk";

import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";

import app from "./app/appReducer";
import user from "./user/userReducer";
import submission from "./submission/submissionReducer";
import team from "./team/teamReducer";
import temp from "./temp/tempReducer";

import { LOG_OUT } from "./user/userActions";
import { toggleLoading } from "./app/appActions";

import { createGlobalStore } from "./globalStore";

const appReducer = combineReducers({
  app,
  user,
  submission,
  team,
  temp,
});

const rootReducer = (state, action) => {
  if (action.type === LOG_OUT) {
    storage.removeItem("persist:veto20");
    state = undefined;
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: "veto201",
  storage,
  timeout: 10000,
  blacklist: ["app"],
  // whitelist: [],
  // whitelist: ['user'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  const store = createStore(persistedReducer, applyMiddleware(thunk));
  const persistor = persistStore(store, null, () => {
    store.dispatch(toggleLoading(false));
  });
  createGlobalStore(store);
  return { store, persistor };
};
