import { TEMP_TOKEN } from "./tempActions";
import { VERIFICATION_REQUESTED } from "../user/userActions";

const initialState = {
  accessToken: null,
  refreshToken: null,
  email: null,
};

const tempReducer = (state = initialState, action) => {
  switch (action.type) {
    case VERIFICATION_REQUESTED: {
      let ns = Object.assign({}, state, {
        email: action.data,
        accessToken: null,
      });
      console.log("VERIFICATION_REQUESTED", ns);
      return ns;
    }
    case TEMP_TOKEN: {
      let ns = {
        ...state,
        accessToken: action.data.accessToken,
        refreshToken: action.data.refreshToken,
      };
      console.log("TEMP_TOKEN", ns);
      return ns;
    }
    default: {
      return state;
    }
  }
};

export default tempReducer;
