import React from "react";
import { useTranslation } from "react-i18next";

const SubmittedView = (props) => {
  const { t } = useTranslation();

  function go(where) {
    props.router.navigate(where);
  }

  return (
    <div className="wizard-content submitted">
      <h2>{t("Done")}!</h2>
      <h3>{t("Your song is submitted")}</h3>
      <div className="continue">
        <div className="half">
          <div className="half-content">
            <button onClick={() => go("/home/submission")}>
              {t("Add submission")}
            </button>
            <div className="info">{t("Submit another song")}</div>
          </div>
        </div>
        <div className="half">
          <div className="half-content">
            <button onClick={() => go("/home")}>{t("Your submissions")}</button>
            <div className="info">
              {t("See a list of all your submissions")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubmittedView;
