import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "./locales/en.json";
import sv from "./locales/sv.json";

export default (store) => {
  i18n.use(LanguageDetector).use(initReactI18next).init({
    resources: {
      en,
      sv,
    },
    fallbackLng: "en",
    keySeparator: ".",
    nsSeparator: false,
  });
};
