import React from "react";

import RTooltip from "react-tooltip-lite";

const Tooltip = (props) => {
  return (
    <div className="tooltip">
      <RTooltip content={props.text} useDefaultStyles={true}>
        <div className="icon-info-circled" />
      </RTooltip>
    </div>
  );
};

export default Tooltip;
