import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const TeamMember = (props) => {
  const { t } = useTranslation();
  const { data, expanded, setExpanded } = props;

  const [email, setEmail] = useState(data.email || "");
  const [pendingEmail] = useState(data.pendingEmail || "");

  function inviteTeamMember() {
    let userData = data;
    userData.email = email;
    props.inviteTeamMember(userData);
  }
  return (
    <div className={"team-member" + (expanded ? " expanded" : "")}>
      <div
        onClick={(e) => {
          setExpanded(data.userId);
        }}
        className="name"
      >
        {data.name}
      </div>
      {expanded && !data.email && !pendingEmail && (
        <div className="inputs">
          <div className="input">
            <label className="left">{t("E-mail address")}</label>
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) => {
                setEmail(e.currentTarget.value);
              }}
            />
          </div>
          <button className="small" onClick={inviteTeamMember}>
            {t("Invite")}
          </button>
        </div>
      )}
      {expanded && (data.email || pendingEmail) && (
        <div className="inputs">
          <div className="input">
            <label className="left">{t("E-mail address")}</label>
            <input
              type="email"
              name="email"
              defaultValue={email || pendingEmail}
              disabled={true}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TeamMember;
