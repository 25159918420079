import { PureComponent } from "react";
import { connect } from "react-redux";

import withRouter from "../global/withRouter";
import { withCookies } from "react-cookie";

import { sendCode } from "../../data/user/userActions";

const mapStateToProps = (state, ownProps) => {
  const user = state.user.user;
  console.log("user", user.accessToken, user.type, user.verified);
  if (user && user.accessToken && user.type > 0 && user.verified) {
    setTimeout(() => {
      ownProps.router.navigate("/home/");
    }, 10);
    return {
      redirect: true,
    };
  }

  if (user && user.accessToken && user.type === 0 && user.verified) {
    setTimeout(() => {
      ownProps.router.navigate("/signin/details/");
    }, 10);
    return {
      redirect: true,
    };
  }
  const { code } = ownProps.match.params;
  let email = user.email;

  if (!email || email.length < 4) {
    email = state.temp.email;
  }

  if (!email || email.length < 4) {
    email = ownProps.cookies.get("veto:email");
  }

  if (!email || email.length < 4) {
    const dec = window.atob(code);
    email = dec.split(":")[0];
  }

  return {
    code,
    email,
    user,
    requesting: state.user.requesting,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendCode: (data) => dispatch(sendCode(data)),
  };
};

class SignInWithCode extends PureComponent {
  state = {};

  static getDerivedStateFromProps(nextProps) {
    console.log({ nextProps });
    if (nextProps.redirect || nextProps.requesting) {
      console.log("redirect");
      return null;
    }
    if (nextProps.email && nextProps.code) {
      if (
        parseInt(nextProps.code, 10).toString() === nextProps.code.toString()
      ) {
        nextProps.sendCode({ email: nextProps.email, code: nextProps.code });
      } else {
        nextProps.sendCode({ email: nextProps.email, code: nextProps.code });
      }
    } else if (nextProps.user && nextProps.user.email && nextProps.code) {
      if (
        parseInt(nextProps.code, 10).toString() === nextProps.code.toString()
      ) {
        nextProps.sendCode({
          email: nextProps.user.email,
          code: nextProps.code,
        });
      } else {
        nextProps.sendCode({
          email: nextProps.user.email,
          code: nextProps.code,
        });
      }
    }
    return null;
  }

  componentDidMount() {}

  render() {
    return null;
  }
}

export default withCookies(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(SignInWithCode)),
);
