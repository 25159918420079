import {
  ON_VERIFIED,
  ON_UNO_USER,
  ON_VETO_USER,
  ON_USER_SUBMISSIONS,
  ON_INVITATION,
  REFRESH_AUTH,
  REQUESTING_AUTH,
  STORE_CHALLENGE,
} from "./userActions";

const initialState = {
  isAuthenticated: false,
  invitation: null,
  user: {
    userId: null,
    unoId: null,
    email: null,
    verified: false,
    type: null,
    accessToken: null,
    refreshToken: null,
    name: null,
    gender: null,
    previousContestant: null,
    accounts: [],
  },
  challenge: null,
  submissions: [],
  requesting: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case ON_VERIFIED: {
      let user = Object.assign({}, state.user);
      user.accessToken = action.data.accessToken;
      user.refreshToken = action.data.refreshToken;

      let ns = Object.assign({}, state, { user, isAuthenticated: true });
      return ns;
    }
    case ON_UNO_USER: {
      let user = Object.assign({}, state.user);
      user.unoId = action.data.userId;
      user.accounts = action.data.accounts;
      action.data.accounts.forEach((a) => {
        if (a.type === "email") {
          user.email = a.value;
          user.verified = a.verified;
        }
      });
      let ns = Object.assign({}, state, { user });
      return ns;
    }
    case ON_VETO_USER: {
      let user = Object.assign({}, state.user);
      user.userId = action.data.id;
      user.unoId = action.data.unoId;
      user.type = action.data.type;
      user.name = action.data.name;
      user.gender = action.data.gender;
      user.previousContestant = action.data.previousContestant;
      user.accessToken = action.data.accessToken
        ? action.data.accessToken
        : user.accessToken;
      user.refreshToken = action.data.refreshToken
        ? action.data.refreshToken
        : user.refreshToken;
      user.email = action.data.email ? action.data.email : user.email;
      user.phone = action.data.phone ? action.data.phone : user.phone;

      let ns = Object.assign({}, state, { invitation: null, user });
      console.log("ON_VETO_USER", user);
      return ns;
    }
    case ON_USER_SUBMISSIONS: {
      let submissions = [];
      action.data.forEach((s) => {
        s.credits = JSON.parse(s.credits);

        if (s.uploader) {
          s.uploader = JSON.parse(s.uploader);
        }
        submissions.push(s);
      });
      let ns = Object.assign({}, state, { submissions });
      return ns;
    }
    case ON_INVITATION: {
      let ns = Object.assign({}, state, {
        invitation: { email: action.data.email, code: action.data.code },
      });
      return ns;
    }
    case REFRESH_AUTH: {
      const user = { ...state.user };
      user.accessToken = action.data.accessToken;
      user.refreshToken = action.data.refreshToken;
      console.log("REFRESH_AUTH", user);
      return { ...state, user };
    }
    case REQUESTING_AUTH: {
      let ns = Object.assign({}, state, {
        requesting: action.data.requesting,
      });
      return ns;
    }
    case STORE_CHALLENGE: {
      const ns = { ...state, challenge: action.data };
      console.log({ ns });
      return ns;
    }
    default: {
      return state;
    }
  }
};

export default userReducer;
