import React from "react";

const AudioPlayer = (props) => {
  return (
    <audio
      onClick={(e) => e.stopPropagation()}
      controls={true}
      src={props.file}
    />
  );
};

export default AudioPlayer;
