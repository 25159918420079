import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import AudioPlayer from "../global/AudioPlayer";
import { GENRES, LANGUAGES, STATUSES } from "../../data/constants";

const SubmissionItem = (props) => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const submission = props.data;
  const { owner } = props;

  if (!submission) {
    return null;
  }

  function toggleExpand(e) {
    e.stopPropagation();
    e.preventDefault();
    setExpanded(!expanded);
  }

  function onClickSubmission() {
    if (owner) {
      props.onClick(submission.id);
    }
  }

  let credits = [];
  let lm = [];
  let l = [];
  let m = [];

  if (typeof submission.credits === "string") {
    try {
      submission.credits = JSON.parse(submission.credits);
    } catch (e) {
      let cred = [];
      let sc = submission.credits
        .replace(`[{`, "")
        .replace(`}]`, "")
        .split(",");
      sc.forEach((ss) => {
        let o = {};

        ss = ss.replace(/(")/g, "");
        let os = ss.split(":");
        o[os[0]] = os[1];
        cred.push(o);
      });
      submission.credits = cred;
    }
  }

  submission.credits &&
    submission.credits[0] &&
    submission.credits.forEach((c) => {
      if (c && c.credits) {
        if (c.credits === 3) {
          lm.push(c.name);
        } else if (c.credits === 1) {
          l.push(c.name);
        } else if (c.credits === 2) {
          m.push(c.name);
        }
      }
    });
  if (lm.length > 0) {
    credits.push(
      <div className="credits-string" key="lm-credits">
        <b>{t("Lyrics & music")}: </b>
        {lm.join(", ")}
      </div>,
    );
  }
  if (l.length > 0) {
    credits.push(
      <div className="credits-string" key="l-credits">
        <b>{t("Lyrics")}: </b>
        {l.join(", ")}
      </div>,
    );
  }
  if (m.length > 0) {
    credits.push(
      <div className="credits-string" key="m-credits">
        <b>{t("Music")}: </b>
        {m.join(", ")}
      </div>,
    );
  }

  let status = t("incomplete");
  if (submission.step >= 10) {
    status = t(STATUSES[submission.step]);
  }
  let verifyNotes = [];
  if (submission.step < 10 && submission.verifyNotes) {
    const vn = JSON.parse(submission.verifyNotes);
    vn.forEach((v) => {
      verifyNotes.push(
        <div className="note" key={v}>
          • {t(`missing.${v}`)}
        </div>,
      );
    });
  }

  let image = submission.imageFileUri;
  if (!image) {
    image =
      "https://svt-veto-files.s3-eu-west-1.amazonaws.com/default-image.png";
  }
  return (
    <div
      className={
        "submission-item boxed status-" +
        submission.step +
        (owner ? " owner" : "")
      }
    >
      <div className="status-tag">
        {status}
        {verifyNotes.length > 0 && (
          <span className="verify-info">
            <i className="icon-info-circled" />{" "}
            <div className="verify-notes">{verifyNotes}</div>
          </span>
        )}
      </div>

      <div className="info" onClick={onClickSubmission}>
        <div className="image" style={{ backgroundImage: `url("${image}")` }} />
        <div className="submission-info">
          <div className="bottom">
            <div className="title">{submission.title}</div>
            <div className="artist">{submission.artistName}</div>
            <button className="nano" onClick={toggleExpand}>
              {expanded ? t("Close") : t("More info")}
            </button>
          </div>
        </div>
      </div>
      {expanded && (
        <div className="credits" onClick={onClickSubmission}>
          {credits}
          <div className="credits-string">
            <b>{t("Genre")}:</b> {GENRES[submission.genre]}
          </div>
          <div className="credits-string">
            <b>{t("Language")}:</b> {t(LANGUAGES[submission.language])}
          </div>
          <div className="credits-string">
            <b>{t("Uploader")}:</b> {submission.uploader.name}
          </div>
          <div className="credits-string">
            <b>{t("Comment")}:</b> {submission.comment}
          </div>
        </div>
      )}
      <AudioPlayer file={submission.audioFileUri} />
    </div>
  );
};

export default SubmissionItem;
