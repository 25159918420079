import { connect } from "react-redux";

import withRouter from "../global/withRouter";
import HomeView from "./HomeView";

import { getUserSubmissions } from "../../data/user/userActions";
import { selectSubmission } from "../../data/submission/submissionActions";

const mapStateToProps = (state, ownProps) => {
  return {
    submissions: state.user.submissions || [],
    user: state.user.user,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    selectSubmission: (id) => {
      dispatch(selectSubmission(id));
    },
    getUserSubmissions: () => dispatch(getUserSubmissions()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomeView),
);
