import axios from "axios";
import arrayFind from "lodash/find";

import { find, get, create, patch } from "../api";
import errorHandler from "../errorHandler";

export const GENRES = "GENRES";
export const START = "START";
export const UPLOAD = "UPLOAD";
export const INFO = "INFO";
export const SUBMIT = "SUBMIT";
export const RESET_SUBMISSION = "RESET_SUBMISSION";
export const SELECT_SUBMISSION = "SELECT_SUBMISSION";
export const RESET_FILES = "RESET_FILES";

const onGenresSuccess = (data) => {
  return {
    type: GENRES,
    data,
  };
};

export const getGenres = () => {
  return async (dispatch, getCurrentState) => {
    const user = getCurrentState().user.user;
    return find("genre", null, user)
      .then((result) => {
        return dispatch(onGenresSuccess(result.data.data));
      })
      .catch((error) => {
        return errorHandler(error, dispatch);
      });
  };
};

export const getSubmission = (id) => {
  return async (dispatch, getCurrentState) => {
    const user = getCurrentState().user.user;
    return get("submission", id, user)
      .then((result) => {
        return dispatch(selectSubmissionSuccess(result.data.data));
      })
      .catch((error) => {
        return errorHandler(error, dispatch);
      });
  };
};

const onStartSuccess = (data) => {
  return {
    type: START,
    data: {
      id: data.id,
      step: data.step,
    },
  };
};

export const onStart = () => {
  return async (dispatch, getCurrentState) => {
    const user = getCurrentState().user.user;
    const data = {
      step: 1,
      userId: user.userId,
    };
    return create("submission", data, user)
      .then((result) => {
        return dispatch(onStartSuccess(result.data.data));
      })
      .catch((error) => {
        return errorHandler(error, dispatch);
      });
  };
};

const onUploadSuccess = (data) => {
  return {
    type: UPLOAD,
    data,
  };
};

export const onDefaultImage = () => {
  return {
    type: UPLOAD,
    data: {
      type: "image",
      imageFileName: "default-image.png",
      imageFileUri:
        "https://svt-veto-files.s3-eu-west-1.amazonaws.com/default-image.png",
    },
  };
};

export const onUpload = (file, type, onProgress) => {
  return async (dispatch, getCurrentState) => {
    const { submission } = getCurrentState();
    const { id } = submission;
    const user = getCurrentState().user.user;

    const fileParts = file.name.split(".");
    const suffix = Math.floor(Math.random() * 89 + 19);
    const ext = fileParts[fileParts.length - 1];

    dispatch({ type: RESET_FILES, data: { type } });

    return create(
      "generateurl",
      {
        fileName: `${id}-${suffix}.${ext}`,
        ContentType: file.type,
        accessToken: user.accessToken,
      },
      user,
    )
      .then((result) => {
        return axios.put(result.data.data.url, file, {
          headers: {
            "Content-Type": file.type,
          },
          onUploadProgress: (e) => {
            onProgress(e);
          },
        });
      })
      .then((result) => {
        let insertData = {
          type,
          part: "files",
          fileUri: result.config.url.split("?")[0],
          fileName: file.name,
          step: submission.step <= 2 ? 2 : submission.step,
          submissionId: id,
        };
        return create("file", insertData, user);
      })
      .then((result) => {
        let data = result.data.data;
        data.type = type;
        return dispatch(onUploadSuccess(data));
      });
  };
};

const onInfoSuccess = (data) => {
  return {
    type: INFO,
    data,
  };
};

export const onInfo = (info, edit = false) => {
  return async (dispatch, getCurrentState) => {
    const { submission } = getCurrentState();
    const { id } = submission;
    const user = getCurrentState().user.user;
    let data = info;
    let dc = data.credits;
    delete data.credits;
    let step = edit ? 10 : 3;
    if (!submission.audioFileUri) {
      step = 1;
    }
    data.step = step;

    return patch("submission", id, data, user)
      .then((result) => {
        let credits = [];
        dc.forEach((d) => {
          if (d.credits && d.credits > 0) {
            credits.push({
              userId: d.userId,
              type: d.credits,
            });
          }
        });
        return create("credit", { submissionId: id, credits }, user);
      })
      .then((result) => {
        return dispatch(onInfoSuccess(result.data.data));
      })
      .catch((error) => {
        return errorHandler(error, dispatch);
      });
  };
};

const onSubmitSuccess = (data) => {
  return {
    type: SUBMIT,
    data,
  };
};

export const onSubmit = () => {
  return async (dispatch, getCurrentState) => {
    const id = getCurrentState().submission.id;
    const user = getCurrentState().user.user;
    return patch("submission", id, { step: 10 }, user)
      .then((result) => {
        return dispatch(onSubmitSuccess(result.data.data));
      })
      .catch((error) => {
        return errorHandler(error, dispatch);
      });
  };
};

export const resetSubmission = () => {
  return {
    type: RESET_SUBMISSION,
  };
};

export const selectSubmissionSuccess = (data) => {
  return {
    type: SELECT_SUBMISSION,
    data,
  };
};

export const selectSubmission = (id) => {
  return async (dispatch, getCurrentState) => {
    dispatch(resetSubmission());
    const submissions = getCurrentState().user.submissions;
    const submission = arrayFind(submissions, (s) => {
      return s.id === id;
    });
    return dispatch(selectSubmissionSuccess(submission));
  };
};
