/* eslint no-control-regex: 0 */

import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import Header from "../global/header/Header";

const DetailsView = (props) => {
  const { t } = useTranslation();
  const [name, setName] = useState(
    props.user && props.user.name ? props.user.name : "",
  );
  const [phone, setPhone] = useState("");
  const [done, setDone] = useState(false);

  useEffect(() => {
    
    if (name.length > 4 && phone.length > 7) {
      return setDone(true);
    }
    setDone(false);
  }, [name, phone]);

  if (props.redirect) {
    return null;
  }
  function cleanPhone(num) {
    return num.toString().replace(/([^0-9]*)/gi, "");
  }

  function onSubmit(e) {
    if (done) {
      e.preventDefault();
      props.sendDetails({
        name,
        phone: cleanPhone(phone),
      });
    }
  }

  return (
    <div className="details">
      <Header />
      <div className="view centered">
        <div className="center">
          <form className="user-details" onSubmit={onSubmit}>
            <h2>{t("Your account")}</h2>
            <p>
              {t(
                "Before we get into it, we would like to know your name and phone number",
              )}
            </p>
            <div className="input">
              <label htmlFor="name">{t("Name")}</label>
              <input
                type="text"
                className={name.length < 5 ? "err" : ""}
                name="name"
                onChange={(e) => {
                  setName(e.currentTarget.value);
                }}
                value={name}
              />
            </div>
            <div className="input">
              <label htmlFor="phone">{t("Phone number")}</label>
              <input
                type="text"
                name="phone"
                onChange={(e) => {
                  setPhone(cleanPhone(e.currentTarget.value));
                }}
                value={phone}
              />
            </div>
            <div className="controls">
              <button onClick={onSubmit} disabled={done ? false : true}>
                {t("Continue")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DetailsView;
