import React from 'react';

import { Route, Routes, Redirect } from 'react-router-dom';
import withRouter from './components/global/withRouter';
import { isMobile } from 'react-device-detect';

import Splash from './components/splash/Splash';
import MobileWarning from './components/splash/MobileWarning';
import Root from './components/root/Root';
import SignIn from './components/signIn';
import Terms from './components/terms/Terms';
import Loader from './components/global/loader/Loader';
import ErrorPrompt from './components/global/ErrorPrompt';

const App = (props) => {
	if (isMobile) {
		document.body.className = 'bg2';
	}

	return (
		<div className={isMobile ? 'container mobile' : 'container'}>
			{/* <SignIn />
			<Root /> */}
			<Routes>
				{/*<Route path="/signin/*" element={<SignIn />} />
				<Route path="/logga-in/:code" element={<SignIn />} />
				<Route path="/registrera/:code" element={<SignIn />} />
				<Route path="/home/*" element={<Root />} />
				<Route path="/invited/:code?" element={<SignIn />} />
				<Route path="/terms" element={<Terms />} />
				<Route exact path="/" element={<Splash />} />*/}
				<Route path="/" element={<Splash />} />
			</Routes>
			<Loader />
			<ErrorPrompt />
			{/*			{isMobile && (
				<div className="footer_logo_mobile">
					<figure />
				</div>
			)}*/}
		</div>
	);
};

export default withRouter(App);
