import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const StartView = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    props.resetSubmission();
  }, []);

  function next() {
    props.onStart();
    props.goNext(props.location.pathname);
  }

  return (
    <div className="wizard-content start">
      <h2>{t("Before we begin")}</h2>
      <h4>{t("Make sure you have")}:</h4>
      <ul>
        <li>{t("An mp3 file of your song")}</li>
        <li>{t("The song title")}</li>
        <li>{t("Name of the artist")}</li>
        <li>{t("Composers' and authors' names and contact information")}</li>
        <li>{t("A suitable genre")}</li>
        <li>
          {t("An image, in square format, minimum 1000 x 1000 pixel size")}
        </li>
      </ul>
      <div className="controls">
        {/*<button className="inverse" onClick={() => props.history.go(-1)}>
					<i className="icon-left-open" />
					{t('Back')}
				</button>*/}
        <button onClick={next}>{t("Start")}</button>
      </div>
    </div>
  );
};

export default StartView;
