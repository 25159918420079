import React, { useState, useEffect } from "react";

const LoaderView = (props) => {
  const [loading, setLoading] = useState(props.loading);
  const [mode, setMode] = useState("running");

  useEffect(() => {
    if (!props.loading && loading) {
      setMode("out");
      setTimeout(() => {
        setLoading(props.loading);
      }, 500);
    } else if (props.loading && !loading) {
      setLoading(props.loading);
      setMode("in");
      setTimeout(() => {
        setMode("running");
      }, 500);
    }
  }, [props.loading]);

  if (!loading) {
    return null;
  }

  return (
    <div className="loading">
      <div className={"loader " + mode}>
        <div className="one" />
        <div className="two" />
      </div>
    </div>
  );
};

export default LoaderView;
