import SignInView from "./SignInView";
import { connect } from "react-redux";

import withRouter from "../global/withRouter";
import { withCookies } from "react-cookie";

import { signInEmail, getInvitedUser } from "../../data/user/userActions";
import { getTerms } from "../../data/app/appActions";

const mapStateToProps = (state, ownProps) => {
  const user = state.user.user;
  const { temp } = state;
  const invitation = state.user.invitation;

  if (user && temp.email && !temp.accessToken) {
    setTimeout(() => {
      ownProps.router.navigate("/signin/code/");
    }, 10);
    return {
      redirect: true,
    };
  }

  if (user && user.email && user.accessToken && !user.verified) {
    setTimeout(() => {
      ownProps.router.navigate("/signin/verify/");
    }, 10);
    return {
      redirect: true,
    };
  }

  if (user && user.accessToken && user.type > 0 && user.verified) {
    setTimeout(() => {
      ownProps.router.navigate("/home/");
    }, 10);
    return {
      redirect: true,
    };
  }

  if (user && temp.accessToken && user.type === 0 && user.verified) {
    setTimeout(() => {
      ownProps.router.navigate("/signin/details/");
    }, 10);
    return {
      redirect: true,
    };
  }

  return { invitation };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    signInEmail: (data) => dispatch(signInEmail(data, ownProps.cookies)),
    getInvitedUser: (code) => dispatch(getInvitedUser(code)),
    getTerms: () => dispatch(getTerms()),
  };
};

export default withCookies(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(SignInView)),
);
