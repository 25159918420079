import axios from "axios";
import { globalStore } from "./globalStore";

import { API_URL } from "./constants";

axios.defaults.baseURL = API_URL;

const header = (user) => {
  return {
    Authorization: `Bearer ${user.accessToken}`,
    "X-Requested-By": user.unoId,
  };
};

export const get = (service, id, user) => {
  const headers = header(user);
  return makeRequest({
    url: `/${service}/${id}`,
    method: "get",
    headers,
    user,
  });
};

export const find = (service, query, user) => {
  let qs = "";
  if (query) {
    qs += "?";
    Object.keys(query).forEach((q) => {
      qs += `${q}=${query[q]}&`;
    });
    qs = qs.substring(0, qs.length - 1);
  }
  const headers = header(user);
  return makeRequest({
    url: `/${service}${qs}`,
    method: "get",
    headers: headers,
    user,
  });
};

export const create = (service, params, user) => {
  const headers = header(user);
  const payload = {
    url: `/${service}`,
    method: "post",
    data: params,
    headers: headers,
    user,
  };
  return makeRequest(payload);
};

export const patch = (service, id, params, user) => {
  const headers = header(user);
  return makeRequest({
    url: `/${service}/${id}`,
    method: "patch",
    data: params,
    headers: headers,
    user,
  });
};

export const unauthenticatedFind = (service, query) => {
  let qs = "";
  if (query) {
    qs += "?";
    Object.keys(query).forEach((q) => {
      qs += `${q}=${query[q]}&`;
    });
    qs = qs.substring(0, qs.length - 1);
  }
  return makeRequest({
    url: `/${service}${qs}`,
    method: "get",
  });
};

export const submitValidateEmail = (data) => {
  const payload = {
    url: `/user/confirm`,
    method: "post",
    data,
  };
  return makeRequest(payload);
};
let c = 0;
export const submitRefreshTokenAndRetry = async (endPoint, action) => {
  const auth = await submitRefreshToken();
  console.log("submitRefreshTokenAndRetry", auth);
  await new Promise((resolve) => setTimeout(resolve, 1000));
  if (c < 2) {
    c++;
    return action(endPoint, `Bearer ${auth.accessToken}`);
  }
};

export const submitRefreshToken = async (auth) => {
  let { user } = globalStore.getState().user;
  let { refreshToken } = user;
  if (!refreshToken && auth) {
    ({ refreshToken } = auth);
  }
  if (!refreshToken) {
    ({ refreshToken } = globalStore.getState().temp);
  }
  const payload = {
    url: `/user/refresh`,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ token: refreshToken }),
  };
  const response = await makeRequest(payload);
  console.log("submitRefreshToken", response.data.data);
  if (user && user.userId) {
    globalStore.dispatch({
      type: "REFRESH_AUTH",
      data: response.data.data,
    });
  } else {
    globalStore.dispatch({
      type: "TEMP_TOKEN",
      data: response.data.data,
    });
  }
  return response.data.data;
};

const makeRequest = (config) => {
  //const user = config.user;
  delete config.user;
  return axios(config);
};
