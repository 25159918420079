import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
console.log(`steps.${isMobile ? "mobile" : "desktop"}.step1`);
const sections = [
  null,
  { path: "start", title: `steps.${isMobile ? "mobile" : "desktop"}.step1` },
  { path: "upload", title: `steps.${isMobile ? "mobile" : "desktop"}.step2` },
  { path: "info", title: `steps.${isMobile ? "mobile" : "desktop"}.step3` },
  { path: "preview", title: `steps.${isMobile ? "mobile" : "desktop"}.step4` },
  { path: "done", title: `steps.${isMobile ? "mobile" : "desktop"}.step5` },
];

const Breadcrumbs = (props) => {
  const { t } = useTranslation();
  const step = parseInt(props.submission.step, 10);
  let url = props.location.pathname.split("/submission/")[1];
  if (props.location.pathname.indexOf("submission") <= -1) {
    return null;
  }
  if (url === "" || url === undefined) {
    url = "start";
  }

  let els = [];
  for (let i = 1; i <= 5; i++) {
    let cls = "step";

    if (sections[i].path === url) {
      cls += " active";
    } else if (i < step + 1) {
      cls += " done";
    }
    let to = "./" + sections[i].path;
    if (i === 1 || i > step) {
      els.push(
        <div className={cls} key={i}>
          <span className="section">{t(sections[i].title)}</span>
          {i !== 5 && isMobile && (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 60">
              <polygon points="0,0 0,30 0,60 14,30" />
            </svg>
          )}
          {i !== 5 && !isMobile && (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 60">
              <polygon points="0,0 0,30 0,60 14,30" />
            </svg>
          )}
        </div>,
      );
    } else {
      els.push(
        <Link to={to} className={cls} key={i}>
          <span className="section">{t(sections[i].title)}</span>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 60">
            <polygon points="0,0 0,30 0,60 14,30" />
          </svg>
        </Link>,
      );
    }
  }

  return (
    <div className="breadcrumbs">
      <div className="bc-nav">{els}</div>
    </div>
  );
};

export default Breadcrumbs;
