import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

const HeaderView = (props) => {
  const { t, i18n } = useTranslation();

  function changeLanguage(lang) {
    i18n.changeLanguage(lang);
  }

  const { language } = i18n;

  const { location, user, closed } = props;

  const { pathname } = location;
  const [menuOpen, setMenuOpen] = useState(false);

  let path = pathname.replace(/\/.{2}\//g, "");

  function logOut() {
    props.logOut();
    props.router.navigate("/");
  }

  let loggedIn = false;
  if (user && user.name && !closed) {
    loggedIn = true;
  }

  // let yClass = "";
  // if (path.indexOf("submission") < 0 && path.indexOf("edit") > -1) {
  //   yClass = "active";
  // }
  
  if (isMobile && loggedIn) {
    return (
      <header className="menu">
        <div
          className="hamburger"
          onClick={() => {
            setMenuOpen(true);
          }}
        ></div>
        <div className="navigation">
          <Link
            to={"/home/submission/"}
            className={path.indexOf("submission") > -1 ? "active" : ""}
          >
            {t("Add submission")}
          </Link>
          <Link
            to={"/home/"}
            className={
              path.indexOf("home/") > -1 && path.indexOf("submission") <= -1
                ? "active"
                : ""
            }
          >
            {t("Your submissions")}
          </Link>
        </div>
        <div className={menuOpen ? "content open" : "content"}>
          <div className="logo">
            <figure />
          </div>
          <div
            className="close"
            onClick={() => {
              setMenuOpen(false);
            }}
          ></div>
          {user && !closed && (
            <div className="user">
              <h3 style={{ color: "black", marginBottom: 5 }}>
                {t("Signed in as")}
              </h3>
              <div className="user-name">{props.user.name}</div>
              <div className="user-name">{props.user.email}</div>
              <button className="micro" onClick={logOut}>
                {t("Log out")}
              </button>
            </div>
          )}
          <h3 style={{ color: "black", marginBottom: 10 }}>{t("Language")}</h3>
          <div className="lang">
            <Link
              to={path}
              className={language === "sv" ? "active" : ""}
              onClick={(e) => {
                changeLanguage("sv");
              }}
            >
              {t("Swedish")}
            </Link>
            <Link
              to={path}
              className={language === "en" ? "active" : ""}
              onClick={(e) => {
                changeLanguage("en");
              }}
            >
              {t("English")}
            </Link>
          </div>
        </div>
      </header>
    );
  }
    if (isMobile && !loggedIn) {
    return (
      <header className="menu">
        <div
          className="hamburger"
          onClick={() => {
            setMenuOpen(true);
          }}
        ></div>
       
        <div className={menuOpen ? "content open" : "content"}>
          
          <div
            className="close"
            onClick={() => {
              setMenuOpen(false);
            }}
          ></div>
          {user && !closed && (
            <div className="user">
              <h3 style={{ color: "black", marginBottom: 5 }}>
                {t("Signed in as")}
              </h3>
              <div className="user-name">{props.user.name}</div>
              <div className="user-name">{props.user.email}</div>
              <button className="micro" onClick={logOut}>
                {t("Log out")}
              </button>
            </div>
          )}
          <h3 style={{ color: "black", marginBottom: 10 }}>{t("Language")}</h3>
          <div className="lang">
            <Link
              to={path}
              className={language === "sv" ? "active" : ""}
              onClick={(e) => {
                changeLanguage("sv");
              }}
            >
              {t("Swedish")}
            </Link>
            <Link
              to={path}
              className={language === "en" ? "active" : ""}
              onClick={(e) => {
                changeLanguage("en");
              }}
            >
              {t("English")}
            </Link>
          </div>
        </div>
      </header>
    );
  }
  return (
    <header className={loggedIn ? "" : "splash-header"}>
      <div className="logo">
        <figure />
      </div>
      {loggedIn && (
        <div className="navigation">
          <Link
            to={"/home/submission/"}
            className={path.indexOf("submission") > -1 ? "active" : ""}
          >
            {t("Add submission")}
          </Link>
          <Link
            to={"/home/"}
            className={
              path.indexOf("home/") > -1 && path.indexOf("submission") <= -1
                ? "active"
                : ""
            }
          >
            {t("Your submissions")}
          </Link>
        </div>
      )}
      <div className="controls">
        <div className="lang">
          <Link
            to={path}
            className={language === "sv" ? "active" : ""}
            onClick={(e) => {
              changeLanguage("sv");
            }}
          >
            SV
          </Link>
          <Link
            to={path}
            className={language === "en" ? "active" : ""}
            onClick={(e) => {
              changeLanguage("en");
            }}
          >
            EN
          </Link>
        </div>
        {user && !closed && (
          <div className="user">
            <div className="user-name">{props.user.name}</div>
            <div className="user-name">{props.user.email}</div>
            <button className="micro" onClick={logOut}>
              {t("Log out")}
            </button>
          </div>
        )}
      </div>
    </header>
  );
};

export default HeaderView;
