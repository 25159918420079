import { UNO_PARAMS } from "./constants";
import { globalStore } from "./globalStore";
import { submitRefreshTokenAndRetry } from "./api";
const { unoUrl, unoAuth, termsUrl } = UNO_PARAMS;

export const submitLoginEmail = (data) => {
  return post(`${unoUrl}/login/email`, data);
};

export const submitRegisterEmail = (data) => {
  return post(`${unoUrl}/registration/email`, data);
};

export const submitLoginFb = (data) => {
  return post(`${unoUrl}/login/facebook`, data);
};

export const submitLoginGoogle = (data) => {
  return post(`${unoUrl}/login/google`, data);
};

export const submitExchangeCode = (data) => {
  return postForm(
    `${unoUrl}/token/exchange`,
    Object.keys(data)
      .map((key) => {
        return `${key}=${data[key]}`;
      })
      .join("&"),
  );
};

export const submitAcceptTerms = (approvedTermsVersion, accessToken) => {
  console.log("submitTermsAccepted", approvedTermsVersion, accessToken);
  return patch(
    `${unoUrl}/users/me/terms`,
    { approvedTermsVersion },
    `Bearer ${accessToken}`,
  );
};

export const submitVerifyEmail = (code) => {
  return post(`${unoUrl}/verify/email`, { verificationCode: code });
};

export const getUser = (accessToken) => {
  return get(`${unoUrl}/users/me`, `Bearer ${accessToken}`, get);
};

export const requestTerms = () => {
  return get(termsUrl);
};

const post = (endPoint, data, auth = unoAuth) => {
  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Uno-Client": "Veto",
    },
    body: JSON.stringify(data),
  };
  if (auth) {
    request.headers.Authorization = auth;
  }
  return requestAuth(endPoint, request, post);
};

const patch = (endPoint, data, auth = unoAuth) => {
  const request = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "X-Uno-Client": "Veto",
    },
    body: JSON.stringify(data),
  };
  if (auth) {
    request.headers.Authorization = auth;
  }
  return requestAuth(endPoint, request, post);
};

const postForm = (endPoint, data, auth = unoAuth) => {
  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: auth,
      "X-Uno-Client": "Veto",
    },
    body: data,
  };
  return requestAuth(endPoint, request, post);
};

const postCookie = (endPoint, cookie) => {
  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Uno-Client": "Veto",
      Cookie: cookie,
    },
  };
  return requestAuth(endPoint, request, postCookie);
};

const get = (endPoint, auth = unoAuth) => {
  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: auth,
      "X-Uno-Client": "Veto",
    },
  };
  console.log("get", endPoint, request);
  return requestAuth(endPoint, request, get);
};

const requestAuth = (endPoint, headers, action = null) => {
  console.log("requestAuth", endPoint, headers);
  return fetch(endPoint, headers)
    .then((result) => {
      console.log("requestAuth", endPoint, headers, result);
      if (!result.ok) {
        throw result;
      }
      return result.json();
    })
    .then((result) => {
      if (result.type && result.status >= 400) {
        throw new Error(result);
      }
      return result;
    })
    .catch((error) => {
      /* eslint-disable */
      return error.json().then((err) => {
        switch (err.type) {
          case "BAD_REQUEST": {
            return Promise.reject({ type: "INVALID_INPUT" });
          }
          case "NO_INPUT": {
            return Promise.reject({ type: "INVALID_INPUT" });
          }
          case "DATABASE_ERROR": {
            return Promise.reject({ type: "DATABASE_ERROR" });
          }
          case "INVALID_TOKEN": {
            console.log(
              "Access token became invalid. Trying to fetch a new token.",
            );
            return submitRefreshTokenAndRetry(endPoint, action);
          }
          case "ACCEPTED_TERMS_OUTDATED": {
            console.log("requestAuth.ACCEPTED_TERMS_OUTDATED", err);
            return submitTermsAccepted(endPoint, headers, action);
          }
          default: {
            return Promise.reject(err);
          }
          /* eslint-disable */
        }
      });
    });
};

export const submitTermsAccepted = (endPoint, headers, action) => {
  const { version } = globalStore.getState().app.terms;
  const { accessToken, refreshToken } = globalStore.getState().temp;

  return patch(
    `${unoUrl}/users/me/terms`,
    { approvedTermsVersion: version },
    `Bearer ${accessToken}`,
  )
    .then((result) => {
      console.log("submitTermsAccepted.result", result);
      if (result) {
        return submitRefreshTokenAndRetry(endPoint, action);
      }
      return Promise.reject({ type: "INVALID_TERMS_VERSION" });
    })
    .catch((error) => {
      console.log("submitTermsAccepted.error", error);
      return Promise.reject(error);
    });
};
