import { connect } from "react-redux";
import withRouter from "../withRouter";
import PromptView from "./PromptView";

import { togglePrompt } from "../../../data/app/appActions";

const mapStateToProps = (state) => {
  return {
    component: state.app.prompt,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closePrompt: () => dispatch(togglePrompt()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PromptView),
);
