/* eslint no-control-regex: 0 */

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Header from "../global/header/Header";
import Terms from "./Terms";

const SignInView = (props) => {
  const { getTerms } = props;
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [termsShowing, setTermsShowing] = useState(false);

  function onSubmit(e) {
    if (done) {
      e.preventDefault();
      props.signInEmail({
        email,
      });
      setLoading(true);
    }
  }

  useEffect(() => {
    getTerms();
  }, []);

  useEffect(() => {
    if (props.router.params && props.router.params.code) {
      props.getInvitedUser(props.router.params.code);
    }
  }, []);

  useEffect(() => {
    if (props.invitation && props.invitation.email) {
      setEmail(props.invitation.email);
    }
  }, [props.invitation]);

  useEffect(() => {
    const pattern =
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    if (email.match(pattern) && termsAccepted) {
      setDone(true);
    } else {
      setDone(false);
    }
  }, [email, termsAccepted]);

  if (props.redirect) {
    return null;
  }
  return (
    <div className={"splash signin" + (loading ? " loading" : "")}>
      <Header />
      <div className="centered static-width">
        <h1>{t("Sign in")}</h1>
        <p>
          {t(
            "If you don't have an account, create one by entering your email address below.",
          )}
        </p>
        <p>
          {t(
            "Do you already have an account? Log in with your e-mail address below.",
          )}
        </p>
        <form className="sign-in" onSubmit={onSubmit}>
          <div className="input">
            <label htmlFor="email">{t("E-mail address")}</label>
            <input
              type="email"
              name="email"
              onChange={(e) => {
                setEmail(e.currentTarget.value);
              }}
              value={email}
            />
          </div>
          <div className="confirm-terms">
            <label htmlFor="termsAccepted">
              <input
                type="checkbox"
                name="termsAccepted"
                id="termsAccepted"
                checked={termsAccepted}
                onChange={(e) => {
                  console.log(e.currentTarget.checked);
                  setTermsAccepted(e.currentTarget.checked);
                }}
              />
              <span>
                Jag godkänner{" "}
                <a href="#" className="plain" onClick={setTermsShowing}>
                  användarvillkoren
                </a>
              </span>
            </label>
          </div>
          <div className="controls">
            <button onClick={onSubmit} disabled={done ? false : true}>
              {t("Sign in")}
            </button>
          </div>
        </form>
      </div>
      {termsShowing && (
        <Terms type="read" clickClose={() => setTermsShowing(false)} />
      )}
    </div>
  );
};

export default SignInView;
