import React from "react";
import { connect } from "react-redux";
import withRouter from "../global/withRouter";

const Terms = ({ clickClose, type, acceptTerms, terms }) => {
  if (!terms) {
    return null;
  }

  return (
    <div className="terms">
      <h1>
        För det fall du väljer att skapa ett användarkonto gäller följande
        sekretesspolicy
      </h1>
      {terms.elements.map((e, idx) => {
        if (e.children) {
          const children = [];
          e.children.forEach((ce, cidx) => {
            children.push(
              React.createElement(ce.type, { key: `c${cidx}` }, ce.content),
            );
          });
          return React.createElement(e.type, { key: idx }, children);
        }
        return React.createElement(e.type, { key: idx }, e.content);
      })}
      {type === "read" ? (
        <button onClick={clickClose}>Stäng</button>
      ) : (
        <button onClick={acceptTerms}>Godkänn</button>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { terms } = state.app;
  return { terms, ...ownProps };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Terms));
