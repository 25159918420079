import { submitRefreshToken } from "./api";
import { REFRESH_AUTH } from "./user/userActions";
import { ERROR } from "./app/appActions";

export default (error, dispatch, user = null, callback = null) => {
  console.log("errorHandler", error);
  return new Promise((resolve, reject) => {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.type === "INVALID_TOKEN"
    ) {
      refreshToken(user)
        .then((result) => {
          return dispatch({
            type: REFRESH_AUTH,
            data: result.data,
          });
        })
        .then(() => {
          return resolve(callback);
        })
        .catch((error) => {
          //
        });
    } else if (
      (error && error === "TOO_MANY_REQUESTS") ||
      (error && error.type && error.type === "TOO_MANY_REQUESTS")
    ) {
      return dispatch({
        type: ERROR,
        data: "TOO_MANY_REQUESTS",
      });
    } else if (error && error === "EMAIL_VERIFICATION_ERROR") {
      return dispatch({
        type: ERROR,
        data: "EMAIL_VERIFICATION_ERROR",
      });
    } else if (error && error === "ACCEPTED_TERMS_OUTDATED") {
      console.log("errorHandler", error);
      /* return dispatch({
				type: ERROR,
				data: 'EMAIL_VERIFICATION_ERROR',
			}); */
    }
  });
};

const refreshToken = (user) => {
  return submitRefreshToken(user);
};
