import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import AudioPlayer from "../global/AudioPlayer";
import { useNavigate } from "react-router-dom";
import { GENRES, LANGUAGES } from "../../data/constants";
import Check from "../global/Check";

const PreviewView = (props) => {
  const [done, setDone] = useState(false);
  const { submission } = props;
  const navigate = useNavigate();

  const { t } = useTranslation();
  let image = submission.imageFileUri && submission.imageFileUri;

  if (!image) {
    image =
      "https://svt-veto-files.s3-eu-west-1.amazonaws.com/default-image.png";
  }

  function onSubmit() {
    props.onSubmit();
    props.goNext(props.location.pathname);
  }

  let credits = [];
  let lm = [];
  let l = [];
  let m = [];
  submission.credits &&
    submission.credits.forEach((c) => {
      if (c.credits === 3) {
        lm.push(c.name);
      } else if (c.credits === 1) {
        l.push(c.name);
      } else if (c.credits === 2) {
        m.push(c.name);
      }
    });
  if (lm.length > 0) {
    credits.push(
      <div className="credits-string" key="lm-credits">
        <b>{t("Lyrics & music")}: </b>
        {lm.join(", ")}
      </div>,
    );
  }
  if (l.length > 0) {
    credits.push(
      <div className="credits-string" key="l-credits">
        <b>{t("Lyrics")}: </b>
        {l.join(", ")}
      </div>,
    );
  }
  if (m.length > 0) {
    credits.push(
      <div className="credits-string" key="m-credits">
        <b>{t("Music")}: </b>
        {m.join(", ")}
      </div>,
    );
  }

  if (!submission.title) {
    return null;
  }

  return (
    <div className="wizard-content preview">
      <div className="item boxed">
        <div className="info">
          <div className="title">{submission.title}</div>
          <div className="artist">{submission.artistName}</div>
          <div>{credits}</div>
          <div className="credits-string">
            <b>{t("Genre")}:</b> {GENRES[submission.genre]}
          </div>
          <div className="credits-string">
            <b>{t("Language")}:</b> {t(LANGUAGES[submission.language])}
          </div>
          <div className="credits-string">
            <b>{t("Optional comment, biography, etc")}:</b> {submission.comment}
          </div>
        </div>
        <div className="image-frame">
          <div className="image" style={{ backgroundImage: `url(${image})` }} />
        </div>
        <AudioPlayer file={submission.audioFileUri} />
      </div>
      <div className="final-submit">
        <label>
          <Check
            onClick={setDone}
            checked={done}
            label={t(
              "I hereby confirm that this submission has not been published or made public in any form",
            )}
          />
          <br />
          {t("Read the Melodfestivalen")}{" "}
          <a href={t("terms-link")} target="_blank" rel="noopener noreferrer">
            {t("terms and conditions (in Swedish)")}
          </a>
          .
        </label>
        <div className="controls">
          <button className="inverse" onClick={() => navigate(-1)}>
            <i className="icon-left-open" />
            {t("Back")}
          </button>
          <button onClick={onSubmit} disabled={done ? false : true}>
            {t("Submit")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PreviewView;
