import React from "react";
import { useTranslation } from "react-i18next";

import Check from "../global/Check";
import CreditsPrompt from "./CreditsPrompt";

const Credits = (props) => {
  const { t } = useTranslation();

  let els = [];
  let authors = props.data;
  if (typeof authors === "string") {
    authors = JSON.parse(props.data);
  }
  if (!authors) {
    authors = [];
  }

  authors &&
    authors.forEach((c) => {
      els.push(
        <div className="credits-item" key={c.userId}>
          <div className="name">{c.name}</div>
          <div className="inputs">
            <Check
              onClick={(val) => {
                props.onAddCreditType(val, c.userId, 1);
              }}
              checked={c.credits === 1 || c.credits === 3}
              label={t("Lyrics")}
            />

            <Check
              onClick={(val) => {
                props.onAddCreditType(val, c.userId, 2);
              }}
              checked={c.credits === 2 || c.credits === 3}
              label={t("Music")}
            />
          </div>
        </div>,
      );
    });

  return (
    <div className="input-field">
      <div className="credits-list">{els}</div>
      <button
        className="small"
        onClick={() =>{
          
          props.togglePrompt(
            <CreditsPrompt
              onAddTeamMember={props.onAddTeamMember}
              onSelectMembers={props.onSelectMembers}
              team={props.team}
              credits={props.data}
            />,
          )
        }}
      >
        {t("Add new")}
      </button>
    </div>
  );
};

export default Credits;
