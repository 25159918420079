import React, { useState } from "react";
import find from "lodash/find";
import { useTranslation } from "react-i18next";

import Check from "../global/Check";

const CreditsPrompt = (props) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [previousContestant, setPreviousContestant] = useState(0);
  const [selected, setSelected] = useState(
    props.credits &&
      props.credits.map((c) => {
        return c.userId;
      }),
  );

  function cleanPhone(num) {
    return num.toString().replace(/([^0-9]*)/gi, "");
  }

  function validateEmail(em) {
    /* eslint no-useless-escape: 0 */
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      em,
    );
    //return /^[^@\s]+@[^@\s\.]+\.[^@\.\s]+$/.test(em);
  }

  function missingInfo() {
    if (
      phone.length > 7 &&
      validateEmail(email) &&
      name.length > 2 &&
      gender !== ""
    ) {
      return false;
    }
    return true;
  }

  function onAddMember() {
    props.onAddTeamMember({
      name,
      email,
      phone,
      gender,
      previousContestant,
    });
  }

  function onSelectMembers() {
    props.onSelectMembers(selected);
  }

  function onClickSelect(val) {
    let selection = Object.assign([], selected);
    if (selection.indexOf(val) > -1) {
      selection.splice(selection.indexOf(val), 1);
    } else {
      selection.push(val);
    }
    setSelected(selection);
  }
  let teamEls = [];
  props.team &&
    props.team.forEach((t) => {
      let cUser = find(selected, (c) => {
        return c === t.userId;
      });
      let checked = false;
      if (cUser) {
        checked = true;
      }
      teamEls.push(
        <div className="credits-form" key={t.userId}>
          <Check
            onClick={() => {
              onClickSelect(t.userId);
            }}
            checked={checked}
            label={t.name}
          />
        </div>,
      );
    });
  return (
    <div className="credits-prompt">
      <h2>{t("Add a new author to your team")}</h2>
      <div className="credits-form form-head">
        <div className="credits-item name">{t("Name")}</div>
        <div className="credits-item gender">{t("Gender")}</div>
      </div>

      <div className="credits-form">
        <div className="credits-item name">
          <div className="credits-item-wrapper">
            <input
              type="text"
              name="name"
              value={name}
              onChange={(e) => {
                setName(e.currentTarget.value);
              }}
            />
          </div>
        </div>
        <div className="credits-item gender">
          <div className="credits-item-wrapper">
            <select
              value={gender}
              onChange={(e) => {
                setGender(e.currentTarget.value);
              }}
            >
              <option value="">{t("Select")}...</option>
              <option value="f">{t("Female")}</option>
              <option value="m">{t("Male")}</option>
              <option value="o">{t("Other")}</option>
            </select>
          </div>
        </div>
      </div>
      <div className="credits-form form-head">
        <div className="credits-item phone">{t("Phone number")}</div>
        <div className="credits-item email">{t("E-mail address")}</div>
      </div>

      <div className="credits-form">
        <div className="credits-item phone">
          <div className="credits-item-wrapper">
            <input
              type="text"
              name="phone"
              value={phone}
              onChange={(e) => {
                setPhone(cleanPhone(e.currentTarget.value));
              }}
            />
          </div>
        </div>
        <div className="credits-item email">
          <div className="credits-item-wrapper">
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) => {
                setEmail(e.currentTarget.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="credits-form">
        <div className="credits-item previous">
          <Check
            onClick={(val) => {
              setPreviousContestant(val);
            }}
            label={t("The author has had songs in previous competitions")}
            checked={previousContestant}
          />
        </div>
      </div>
      <div className="credits-form">
        <div className="row">
          <button
            className="small"
            disabled={missingInfo()}
            onClick={onAddMember}
          >
            {t("Add new")}
          </button>
        </div>
      </div>
      {teamEls.length > 0 && (
        <>
          <h3>{t("or select an existing author from your team")}</h3>
          <div className="team-list">{teamEls}</div>
          <div className="row">
            <button className="small" onClick={onSelectMembers}>
              {t("Add selected")}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default CreditsPrompt;
