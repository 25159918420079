import { create, find } from "../api";
import errorHandler from "../errorHandler";

export const ADD_MEMBER = "ADD_MEMBER";
export const INVITE_MEMBER = "INVITE_MEMBER";
export const GET_TEAM = "GET_TEAM";

const addTeamMemberSuccess = (data) => {
  return {
    type: ADD_MEMBER,
    data,
  };
};

export const addTeamMember = (data) => {
  return async (dispatch, getCurrentState) => {
    const user = getCurrentState().user.user;
    const params = {
      leadUserId: user.userId,
      memberUserId: data.userId,
      name: data.name,
      email: data.email,
      phone: data.phone,
      gender: data.gender,
      previousContestant: data.previousContestant,
    };
    return create("team", params, user)
      .then((result) => {
        return dispatch(addTeamMemberSuccess(result.data.data));
      })
      .catch((error) => {
        return errorHandler(error, dispatch);
      });
  };
};

const inviteTeamMemberSuccess = (data) => {
  return {
    type: INVITE_MEMBER,
    data,
  };
};

export const inviteTeamMember = (data) => {
  return async (dispatch, getCurrentState) => {
    const user = getCurrentState().user.user;
    const params = data;
    params.invitedBy = {
      name: user.name,
      email: user.email,
    };
    return create("invite-member", params, user)
      .then((result) => {
        return dispatch(inviteTeamMemberSuccess(result));
      })
      .catch((error) => {
        return errorHandler(error, dispatch);
      });
  };
};

const getTeamSuccess = (data) => {
  return {
    type: GET_TEAM,
    data,
  };
};

export const getTeam = () => {
  return async (dispatch, getCurrentState) => {
    const user = getCurrentState().user.user;
    return find("team", { leadUserId: user.userId }, user)
      .then((result) => {
        return dispatch(getTeamSuccess(result.data.data));
      })
      .catch((error) => {
        return errorHandler(error, dispatch);
      });
  };
};

export const addAndInviteTeamMember = (data) => {
  return async (dispatch, getCurrentState) => {
    return dispatch(addTeamMember(data))
      .then((result) => {
        const inviteData = {
          userId: result.data.id,
          email: data.email,
        };
        return dispatch(inviteTeamMember(inviteData));
      })
      .catch((error) => {
        return errorHandler(error, dispatch);
      });
  };
};
