import { connect } from "react-redux";

import withRouter from "../global/withRouter";

import TeamView from "./TeamView";

import {
  getTeam,
  inviteTeamMember,
  addAndInviteTeamMember,
} from "../../data/team/teamActions";

const mapStateToProps = (state, ownProps) => {
  return {
    members: state.team.members || [],
    user: state.user.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTeam: () => dispatch(getTeam()),
    inviteTeamMember: (data) => dispatch(inviteTeamMember(data)),
    addTeamMember: (data) => dispatch(addAndInviteTeamMember(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TeamView),
);
