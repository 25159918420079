import React, { useState, useEffect } from "react";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import isEqual from "lodash/isEqual";
import { useTranslation } from "react-i18next";

import { GENRES } from "../../data/constants";
import Check from "../global/Check";
import Tooltip from "../global/Tooltip";
import Credits from "../submission/Credits";
import DropArea from "../submission/DropArea";
import { useNavigate } from "react-router-dom";

const EditSubmissionView = (props) => {
  const { t } = useTranslation();
  const { submission, setDefaultImage } = props;
  const navigate = useNavigate();

  const [title, setTitle] = useState(submission.title || "");
  const [artistName, setArtistName] = useState(submission.artistName || "");
  const [artistGender, setArtistGender] = useState(
    submission.artistGender || "",
  );
  const [credits, setCredits] = useState(submission.credits || []);
  const [genre, setGenre] = useState(submission.genre || 0);
  const [language, setLanguage] = useState(submission.language || 0);
  const [demo, setDemo] = useState(submission.demo);
  const [comment, setComment] = useState(submission.comment || "");
  const [pendingCredits, setPendingCredits] = useState([]);
  const [done, setDone] = useState(false);

  useEffect(() => {
    props.togglePrompt();
  }, [submission.credits]);

  useEffect(() => {
    if (credits) {
      let cred = credits.concat(pendingCredits);

      for (let i = 0; i < cred.length; i++) {
        if (!cred[i].userId) {
          cred[i] = find(props.team, (t) => {
            return t.name === cred[i].name;
          });
          if (!cred[i].credits) {
            cred[i].credits = 3;
          }
        }
      }
      setCredits(cred);
    }
  }, [props.team]);

  useEffect(() => {
    props.getTeam();
    props.getSubmission(props.submissionId);
  }, []);

  useEffect(() => {
    checkDone();
  });

  useEffect(() => {
    const s = props.submission;
    setTitle(s.title || "");
    setArtistName(s.artistName || "");
    setArtistGender(s.artistGender || "");
    let cred = [];
    try {
      cred = JSON.parse(s.credits);
    } catch (e) {
      cred = s.credits;
    }
    setCredits(cred);
    setGenre(s.genre || 0);
    setLanguage(s.language || 0);
    setComment(s.comment || "");
    setDemo(s.demo);
    if (!s.imageFileUri || !s.imageFileUri) {
      setDefaultImage();
    }
  }, [props.submission]);

  if (!submission) {
    return null;
  }

  function checkDone() {
    let changes = 0;
    const items = {
      title,
      artistName,
      artistGender,
      credits,
      genre,
      language,
      demo,
      comment,
    };
    Object.keys(items).forEach((i) => {
      if (!isEqual(submission[i], items[i])) {
        changes++;
      }
    });
    if (demo) {
      setArtistName("");
      setArtistGender("");
    }
    if (
      changes > 0 &&
      title &&
      title.length > 0 &&
      ((artistName.length > 2 && artistGender.length > 0) || demo) &&
      credits &&
      credits.length > 0 &&
      genre !== 0 &&
      language !== 0
    ) {
      return setDone(true);
    }
    return setDone(false);
  }

  function onSave() {
    props.onInfo(
      {
        title,
        artistName,
        artistGender,
        credits,
        genre,
        language,
        comment,
        demo,
      },
      true,
    );
    setTimeout(() => {
      props.router.navigate("/home/");
    }, 500);
  }

  function onAddTeamMember(data) {
    props.addTeamMember(data);
    let pc = pendingCredits;
    pc.push(data);
    setPendingCredits(pc);
  }

  function onSelectMembers(data) {
    let cred = [];
    data.forEach((d) => {
      let user = find(credits, (c) => {
        return d === c.userId;
      });

      if (!user) {
        user = find(props.team, (c) => {
          return d === c.userId;
        });
      }
      if (user) {
        user.credits = user.credits ? user.credits : 3;
        cred.push(user);
      }
    });
    setCredits(cred);
    props.togglePrompt();
  }

  function onAddCreditType(val, id, type) {
    let cred = Object.assign([], credits);
    let user = Object.assign(
      {},
      find(cred, (c) => {
        return id === c.userId;
      }),
    );
    let idx = findIndex(cred, (c) => {
      return id === c.userId;
    });
    let typeValue = -type;
    if (val) {
      typeValue = type;
    }
    if (user.credits && user.credits > 0) {
      user.credits += typeValue;
    } else {
      user.credits = typeValue;
    }

    cred[idx] = user;
    setCredits(cred);
  }

  return (
    <div className="edit">
      <div className="edit-content">
        <h2>{t("Edit your submission")}</h2>
        <div className="files">
          <DropArea
            type="audio"
            submission={props.submission}
            mime={["audio/*"]}
            onUpload={props.onUpload}
          />
          <DropArea
            type="image"
            submission={props.submission}
            mime={["image/jpeg", "image/png"]}
            onUpload={props.onUpload}
          />
        </div>
        <div className="info">
          <div className="inputs">
            <div className="input">
              <label htmlFor="title">{t("Title")}</label>
              <input
                type="text"
                name="title"
                id="title"
                onChange={(e) => {
                  setTitle(e.currentTarget.value);
                }}
                value={title}
              />
              <Tooltip text={t("The song title")} />
            </div>
            <div className="artist-input">
              <div className="input">
                <label htmlFor="artistName">{t("Artist name")}</label>
                <input
                  type="text"
                  name="artistName"
                  id="artistName"
                  onChange={(e) => {
                    setArtistName(e.currentTarget.value);
                  }}
                  value={artistName}
                  readOnly={demo}
                />
                <Tooltip
                  text={t(
                    "The artist name. Leave blank if the artist only appears as demo vocals",
                  )}
                />
              </div>
              <div className="input">
                <label htmlFor="artistGender">{t("Artist gender")}</label>
                <select
                  value={artistGender}
                  onChange={(e) => {
                    setArtistGender(e.currentTarget.value);
                  }}
                  readOnly={demo}
                >
                  <option value="">{t("Select")}...</option>
                  <option value="f">{t("Female")}</option>
                  <option value="m">{t("Male")}</option>
                  <option value="o">{t("Female & Male")}</option>
                </select>
                <Tooltip
                  text={t(
                    "The artist's gender. SVT aims to have a gender balanced competition",
                  )}
                />
              </div>
              <div className="input labelcheck">
                <label />
                <div className="labelholder">
                  <Check
                    onClick={(checked) => {
                      setDemo(checked);
                    }}
                    checked={demo}
                    label={t("The artist is only on this demo recording")}
                  />
                  <Tooltip
                    text={t("An artist will be selected at a later date")}
                  />
                </div>
              </div>
            </div>
            <div className="input credits">
              <label htmlFor="credits">{t("Credits")}</label>
              <Credits
                onAddTeamMember={onAddTeamMember}
                onSelectMembers={onSelectMembers}
                onAddCreditType={onAddCreditType}
                team={props.team}
                togglePrompt={props.togglePrompt}
                data={credits}
              />
            </div>
            <div className="input">
              <label htmlFor="genre">{t("Genre")}</label>
              <select
                name="genre"
                value={genre}
                onChange={(e) => {
                  setGenre(e.currentTarget.value);
                }}
              >
                <option value={0}>{t("Select")}...</option>
                {Object.keys(GENRES).map((g) => {
                  const m = t(GENRES[g]);
                  return (
                    <option key={g} value={g}>
                      {m}
                    </option>
                  );
                })}
              </select>
              <Tooltip
                text={t("Pick one genre, even if your song transcends genres")}
              />
            </div>
            <div className="input">
              <label htmlFor="language">{t("Language")}</label>
              <select
                name="language"
                value={language}
                onChange={(e) => {
                  setLanguage(e.currentTarget.value);
                }}
              >
                <option value={0}>{t("Select")}...</option>
                <option value="sv">{t("Swedish")}</option>
                <option value="en">{t("English")}</option>
                <option value="oo">{t("Other")}</option>
              </select>
              <Tooltip
                text={t(
                  "Pick one language, even if your song contains several languages",
                )}
              />
            </div>
            <div className="input">
              <label htmlFor="comment">
                {t("Optional comment, biography, etc")}
              </label>
              <textarea
                type="text"
                name="comment"
                id="comment"
                onChange={(e) => {
                  setComment(e.currentTarget.value);
                }}
                value={comment}
              />
              <Tooltip text={t("Short comment or info about the submission")} />
            </div>
          </div>
        </div>
        <div className="controls">
          <button className="inverse" onClick={() => navigate(-1)}>
            {t("Cancel")}
          </button>
          <button onClick={onSave} disabled={!done}>
            {t("Save")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditSubmissionView;
