import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import withRouter from '../global/withRouter';
import { Routes, Route } from 'react-router-dom';

import withAuthentication from '../global/withAuthentication';

import { getVetoUser } from '../../data/user/userActions';

import Header from '../global/header/Header';
import Home from '../home/Home';
import Team from '../team/Team';
import Submission from '../submission/Submission';
import EditSubmission from '../edit/EditSubmission';
import Prompt from '../global/prompt/Prompt';

const RoutesRoot = (props) => {
	const [loggedIn, setLoggedIn] = useState(props.userRefreshed);

	useEffect(() => {
		props.getVetoUser(props.user.userId);
	}, []);

	useEffect(() => {
		setLoggedIn(props.userRefreshed);
	}, [props.userRefreshed]);

	// if (!loggedIn) {
	// 	return null;
	// }
	console.log(props.user);

	return (
		<>
			{loggedIn && props.user.name !== null && props.user.phone != undefined && <Header />}

			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/team/:userId?" element={<Team />} />
				<Route path="/submission/*" element={<Submission />} />
				<Route path="/edit/:submissionId" element={<EditSubmission />} />
			</Routes>
			<Prompt />
		</>
	);
};

const mapStateToProps = (state, ownProps) => {
	return {
		submissions: state.user.submissions,
		user: state.user.user,
		userRefreshed: state.app.refreshed,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getVetoUser: (id) => dispatch(getVetoUser(id)),
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withAuthentication(RoutesRoot)));
