import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import SubmissionItem from './SubmissionItem';

const HomeView = (props) => {
	const { t } = useTranslation();
	const [submissions, setSubmissions] = useState(props.submissions);

	useEffect(() => {
		props.getUserSubmissions();
	}, []);

	useEffect(() => {
		setSubmissions(props.submissions);
	}, [props.submissions]);

	function editSubmission(id) {
		//props.selectSubmission(id);
		props.router.navigate(`/home/edit/${id}`);
	}

	function start() {
		props.router.navigate('/home/submission/');
	}

	let elsLeft = [];
	let elsRight = [];
	let i = 1;
	submissions &&
		submissions[0] &&
		submissions.forEach((s) => {
			if (s) {
				let owner = false;
				if (s.uploader && s.uploader.userId === props.user.userId) {
					owner = true;
				}
				if (Math.round(i / 2) !== i / 2) {
					elsLeft.push(
						<SubmissionItem onClick={editSubmission} key={s.id} owner={owner} number={i} data={s} />
					);
				} else {
					elsRight.push(
						<SubmissionItem onClick={editSubmission} key={s.id} owner={owner} number={i} data={s} />
					);
				}
				i++;
			}
		});

	return (
		<div className="page home">
			<div className="page-content">
				<div className="deadline center">
					<h2>
						{t('Deadline')}: {t('September 13 at 11:59 AM')}
					</h2>
					<p>
						{t('If you have any questions, contact')}{' '}
						<a href="mailto:mellobidrag@svt.se">mellobidrag@svt.se</a>.
					</p>
					<button onClick={start}>{t('Add submission')}</button>
				</div>
				{submissions && submissions.length > 0 && (
					<div className="submission-list">
						<div className="half">{elsLeft}</div>
						<div className="half">{elsRight}</div>
					</div>
				)}
				{!submissions ||
					(submissions.length === 0 && (
						<div className="empty">
							<h2>{t('Welcome')}</h2>
							<p>
								{t(
									"You don't have any submissions. Use this site to upload your submission and keep track of your submission status."
								)}
							</p>
						</div>
					))}
			</div>
		</div>
	);
};

export default HomeView;
