import {
  submitLoginEmail,
  submitRegisterEmail,
  submitExchangeCode,
  getUser,
  submitVerifyEmail,
  submitAcceptTerms,
} from "../auth";
import { toggleLoading } from "../app/appActions";
import { setTempToken } from "../temp/tempActions";
import {
  get,
  find,
  create,
  patch,
  unauthenticatedFind,
  submitValidateEmail,
  submitRefreshToken,
} from "../api";
import errorHandler from "../errorHandler";
import { genSha256 } from "../../utils";

export const VERIFICATION_REQUESTED = "VERIFICATION_REQUESTED";
export const ON_VERIFIED = "ON_VERIFIED";
export const SIGN_UP = "SIGN_UP";
export const ON_UNO_USER = "ON_UNO_USER";
export const ON_VETO_USER = "ON_VETO_USER";
export const ON_USER_SUBMISSIONS = "ON_USER_SUBMISSIONS";
export const LOG_OUT = "LOG_OUT";
export const ON_INVITATION = "ON_INVITATION";
export const REFRESH_AUTH = "REFRESH_AUTH";
export const REQUESTING_AUTH = "REQUESTING_AUTH";
export const STORE_CHALLENGE = "STORE_CHALLENGE";

export const logOut = () => {
  return {
    type: LOG_OUT,
  };
};

const onVerificationRequested = (data) => {
  return {
    type: VERIFICATION_REQUESTED,
    data,
  };
};

const registerEmail = (data, cookie) => {
  const userData = data;
  return async (dispatch, getCurrentState) => {
    userData.approvedTermsVersion = getCurrentState().app.terms.version;
    return submitRegisterEmail(userData)
      .then((result) => {
        dispatch(toggleLoading(false));
        return dispatch(onVerificationRequested(data.email));
      })
      .catch((error) => {
        return errorHandler(error, dispatch);
      });
  };
};

export const signInEmail = (data, cookie) => {
  return async (dispatch) => {
    const challenge = [...Array(30)]
      .map(() => Math.random().toString(36)[2])
      .join("");
    dispatch({ type: STORE_CHALLENGE, data: challenge });

    const userData = {
      email: data.email,
      challenge,
      challengeMethod: "plain",
    };

    cookie.set("veto:email", data.email);

    dispatch(toggleLoading(true));
    return submitLoginEmail(userData)
      .then((result) => {
        dispatch(toggleLoading(false));
        return dispatch(onVerificationRequested(data.email));
      })
      .catch((error) => {
        console.log("error", error);
        if (error && error.type && error.type === "USER_NOT_FOUND") {
          return dispatch(registerEmail(userData, cookie));
        }
        return errorHandler(error, dispatch);
      });
  };
};

const onVerified = (data) => {
  return {
    type: ON_VERIFIED,
    data,
  };
};

const onGetUnoUser = (data) => {
  return {
    type: ON_UNO_USER,
    data,
  };
};

const onGetVetoUser = (data) => {
  return {
    type: ON_VETO_USER,
    data,
  };
};

export const sendCode = (data) => {
  return async (dispatch, getCurrentState) => {
    dispatch(toggleLoading(true));
    dispatch({ type: REQUESTING_AUTH, data: { requesting: true } });
    const userData = {
      email: data.email || getCurrentState().temp.email,
      code: data.code,
      challengeVerifier: getCurrentState().user.challenge,
    };
    return submitValidateEmail(userData)
      .then((result) => {
        console.log("submitValidateEmail", result);
        return dispatch(authFlow(result.data.data));
      })
      .catch((error) => {
        console.log("sendCode.error", error);
        dispatch(toggleLoading(false));
        return errorHandler(error, dispatch);
      });
  };
};

export const verifyEmail = (code) => {
  return async (dispatch, getCurrentState) => {
    const { user } = getCurrentState().user;
    return submitVerifyEmail(code)
      .then((result) => {
        console.log("after submitVerifyEmail", result);
        if (user && user.accessToken) {
          console.log("user", user);
          return getUser(user.accessToken);
        }
      })
      .then((result) => {
        console.log("after getUser", result);
        dispatch(onGetUnoUser(result.data));
        return dispatch(toggleLoading(false));
      })
      .catch((error) => {
        dispatch(toggleLoading(false));
        return errorHandler(error, dispatch);
      });
  };
};

const authFlow = (auth, cookie) => {
  return async (dispatch) => {
    dispatch(setTempToken(auth));
    return getUser(auth.accessToken)
      .then((result) => {
        console.log("authFlow", result);
        dispatch(onGetUnoUser({ ...result, ...auth }));
        return dispatch(vetoLogin(result.userId));
      })
      .catch((error) => {
        if (error.type && error.type === "ACCEPTED_TERMS_OUTDATED") {
          console.log("ACCEPTED_TERMS_OUTDATED", { error });
          return dispatch(acceptTerms(auth, cookie));
        }
        return errorHandler(error, dispatch);
      });
  };
};

const vetoLogin = (unoId) => {
  return async (dispatch, getCurrentState) => {
    const user = getCurrentState().user;
    let { accessToken, email } = getCurrentState().temp;
    if (!email) {
      email = user.user.email;
    }
    if (!accessToken) {
      accessToken = user.user.accessToken;
    }
    const userData = {
      unoId,
      email,
    };

    if (user.invitation) {
      userData.invitation = user.invitation;
    } else {
      userData.type = 0;
    }

    return create("user", userData, { accessToken, unoId })
      .then((result) => {
        dispatch(toggleLoading(false));
        console.log("vetoLogin", result);
        let response = result.data.data;
        response.accessToken = accessToken;
        response.email = email;
        return dispatch(onGetVetoUser(response));
      })
      .catch((error) => {
        return errorHandler(error, dispatch, user, () => {
          vetoLogin(unoId);
        });
      });
  };
};

const acceptTerms = (auth, cookie) => {
  console.log("acceptTerms", auth.accessToken);
  return async (dispatch, getCurrentState) => {
    await submitAcceptTerms(
      getCurrentState().app.terms.version,
      auth.accessToken,
    );
    return dispatch(authFlow(auth, cookie));
  };
};

export const getVetoUser = (userId) => {
  return async (dispatch, getCurrentState) => {
    const user = getCurrentState().user.user;
    return get("user", userId, user)
      .then((result) => {
        return dispatch(onGetVetoUser(result.data.data));
      })
      .catch((error) => {
        return errorHandler(error, dispatch, user, () => {
          getVetoUser(userId);
        });
      });
  };
};

export const sendDetails = (data) => {
  return async (dispatch, getCurrentState) => {
    const user = getCurrentState().user.user;
    return patch("user", user.userId, data, user)
      .then((result) => {
        return dispatch(onGetVetoUser(result.data.data));
      })
      .catch((error) => {
        return errorHandler(error, dispatch, user, () => {
          sendDetails(data);
        });
      });
  };
};

const onUserSubmissions = (data) => {
  return {
    type: ON_USER_SUBMISSIONS,
    data,
  };
};

export const getUserSubmissions = () => {
  return async (dispatch, getCurrentState) => {
    const user = getCurrentState().user.user;
    find("submission", { userId: user.userId }, user)
      .then((result) => {
        return dispatch(onUserSubmissions(result.data.data));
      })
      .catch((error) => {
        return errorHandler(error, dispatch, user, () => {
          getUserSubmissions();
        });
      });
  };
};

const onInvitedUserSuccess = (data) => {
  return {
    type: ON_INVITATION,
    data,
  };
};

export const getInvitedUser = (code) => {
  return async (dispatch) => {
    unauthenticatedFind("invite-member", { code })
      .then((result) => {
        return dispatch(onInvitedUserSuccess(result.data.data));
      })
      .catch((error) => {
        return errorHandler(error, dispatch);
      });
  };
};
