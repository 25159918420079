import { requestTerms } from "../auth";

export const TOGGLE_PROMPT = "TOGGLE_PROMPT";
export const TOGGLE_LOADING = "TOGGLE_LOADING";
export const ERROR = "ERROR";
export const ON_TERMS = "ON_TERMS";

export const togglePrompt = (component) => {
  return {
    type: TOGGLE_PROMPT,
    component,
  };
};

export const toggleLoading = (data) => {
  return {
    type: TOGGLE_LOADING,
    data,
  };
};

export const showError = (type) => {
  return {
    type: ERROR,
    data: type,
  };
};

export const getTerms = () => {
  return async (dispatch) => {
    const result = await requestTerms();
    return dispatch({
      type: ON_TERMS,
      data: result,
    });
  };
};
