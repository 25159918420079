import { connect } from "react-redux";

import withRouter from "../global/withRouter";

import withAuthentication from "../global/withAuthentication";
import EditSubmissionView from "./EditSubmissionView";

import {
  onUpload,
  onInfo,
  onSubmit,
  getSubmission,
  onDefaultImage,
} from "../../data/submission/submissionActions";

import { togglePrompt } from "../../data/app/appActions";
import { addTeamMember, getTeam } from "../../data/team/teamActions";

const mapStateToProps = (state, ownProps) => {
  const submissionId = ownProps.router.params.submissionId;

  return {
    submissionId,
    submission: state.submission,
    user: state.user.user,
    team: state.team.members,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onUpload: (files, type, onProgress) =>
      dispatch(onUpload(files, type, onProgress)),
    onInfo: (data) => dispatch(onInfo(data, true)),
    onSubmit: (data) => dispatch(onSubmit(data)),
    togglePrompt: (component) => {
      window.scrollTo(0,0);
      dispatch(togglePrompt(component))
    },
    addTeamMember: (data) => dispatch(addTeamMember(data)),
    getSubmission: (id) => dispatch(getSubmission(id)),
    getTeam: () => dispatch(getTeam()),
    setDefaultImage: () => dispatch(onDefaultImage()),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withAuthentication(EditSubmissionView)),
);
