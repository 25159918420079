export const GENRES = {
  1: "Pop",
  2: "Hip hop",
  3: "Ballad",
  4: "Soul/R'n'B",
  5: "House/Techno",
  6: "Country",
  7: "Rock",
  8: "Etno/Folk",
  9: "Schlager/Dansband",
  10: "Other",
};

export const LANGUAGES = {
  sv: "Swedish",
  en: "English",
  oo: "other",
};

export const STATUSES = {
  10: "verifying",
  20: "submitted",
};

const apiUrl = () => {
  let url = "http://localhost:5001";
  if (
    window.location.href.indexOf("melodifestivalen.se") > -1 ||
    window.location.href.indexOf("amazonaws.com") > -1
  ) {
    url = "https://d2bki5l3kotv7y.cloudfront.net";
  } else if (window.location.href.indexOf("iamfoolish.com") > -1) {
    url = "http://api.veto.iamfoolish.com";
  }
  return url;
};

const unoParams = () => {
  let unoUrl = "https://auth.stage.uno.svt.se/authentication/v5";
  let termsUrl = "https://static.stage.uno.svt.se/terms/latest";
  if (
    window.location.href.indexOf("melodifestivalen.se") > -1 ||
    window.location.href.indexOf("amazonaws.com") > -1
  ) {
    unoUrl = "https://auth.prod.uno.svt.se/authentication/v5";
    termsUrl = "https://static.prod.uno.svt.se/terms/latest";
  }
  return { unoUrl, termsUrl };
};

export const API_URL = apiUrl();
export const UNO_PARAMS = unoParams();
