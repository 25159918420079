import React, { useState, useEffect } from "react";

const Check = (props) => {
  let [checked, setChecked] = useState(props.checked);

  let cls = "checkbox";
  if (checked) {
    cls += " checked";
  }

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  function onClick(e) {
    let nc = !checked;
    setChecked(nc);
    props.onClick(nc);
  }

  if (props.label) {
    return (
      <label className="left check" onClick={onClick}>
        <div className={cls} />
        {props.label}
      </label>
    );
  }

  return <div className={cls} onClick={onClick} />;
};

export default Check;
