import { connect } from "react-redux";

import withRouter from "../global/withRouter";
/* import find from 'lodash/find';
import orderBy from 'lodash/orderBy'; */

import withAuthentication from "../global/withAuthentication";
import SubmissionView from "./SubmissionView";

import {
  onStart,
  onUpload,
  onInfo,
  onSubmit,
  resetSubmission,
  onDefaultImage,
} from "../../data/submission/submissionActions";

import { togglePrompt } from "../../data/app/appActions";
import { addTeamMember, getTeam } from "../../data/team/teamActions";

const STEPS = ["", "upload", "info", "preview", "done"];

const goToNextStep = (path, ownProps) => {
  const currentPath = path.split("/submission/")[1];
  ownProps.router.navigate(
    `/home/submission/${STEPS[STEPS.indexOf(currentPath) + 1]}`,
  );
};

const populateCredits = (sub, team) => {
  return sub;
};

const mapStateToProps = (state, ownProps) => {
  return {
    submission: populateCredits(state.submission, state.team.members),
    user: state.user.user,
    team: state.team.members,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    goNext: (path) => goToNextStep(path, ownProps),
    onStart: () => dispatch(onStart()),
    onUpload: (files, type, onProgress) =>
      dispatch(onUpload(files, type, onProgress)),
    setDefaultImage: () => dispatch(onDefaultImage()),
    onInfo: (data) => dispatch(onInfo(data)),
    onSubmit: (data) => dispatch(onSubmit(data)),
    resetSubmission: () => dispatch(resetSubmission()),
    togglePrompt: (component) => {
      window.scrollTo(0,0)
      dispatch(togglePrompt(component))
    },
    addTeamMember: (data) => dispatch(addTeamMember(data)),
    getTeam: () => dispatch(getTeam()),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withAuthentication(SubmissionView)),
);
