import React, { useState, useEffect } from "react";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import { useTranslation } from "react-i18next";

import { GENRES } from "../../data/constants";
import Check from "../global/Check";
import Credits from "./Credits";
import Tooltip from "../global/Tooltip";
import { useNavigate } from "react-router-dom";

const InfoView = (props) => {
  const { t } = useTranslation();
  const { submission } = props;
  const navigate = useNavigate();

  const [title, setTitle] = useState(submission.title || "");
  const [artistName, setArtistName] = useState(submission.artistName || "");
  const [artistGender, setArtistGender] = useState(
    submission.artistGender || "",
  );
  const [demo, setDemo] = useState(submission.demo);
  const [credits, setCredits] = useState(submission.credits || []);
  const [genre, setGenre] = useState(submission.genre || 0);
  const [language, setLanguage] = useState(submission.language || 0);
  const [comment, setComment] = useState(submission.comment || "");
  const [pendingCredits, setPendingCredits] = useState([]);
  const [done, setDone] = useState(false);

  useEffect(() => {
    props.togglePrompt();
  }, [submission.credits]);

  useEffect(() => {
    let cred = credits.concat(pendingCredits);
    for (let i = 0; i < cred.length; i++) {
      if (!cred[i].userId) {
        cred[i] = find(props.team, (t) => {
          return t.name === cred[i].name;
        });
        if (!cred[i].credits) {
          cred[i].credits = 3;
        }
      }
    }
    setCredits(cred);
    setPendingCredits([]);
  }, [props.team]);

  useEffect(() => {
    props.getTeam();
  }, []);

  useEffect(() => {
    checkDone();
  });

  function checkDone() {
    if (demo) {
      setArtistName("");
      setArtistGender("");
    }
    if (
      title.length > 0 &&
      ((artistName.length > 2 && artistGender.length > 0) || demo) &&
      credits.length > 0 &&
      genre !== 0 &&
      language !== 0
    ) {
      return setDone(true);
    }
    return setDone(false);
  }

  function onSave() {
    props.onInfo({
      title,
      artistName,
      artistGender,
      credits,
      genre,
      language,
      demo,
      comment,
    });
    props.goNext(props.location.pathname);
  }

  function onAddTeamMember(data) {
    props.addTeamMember(data);
    let pc = pendingCredits;
    pc.push(data);
    setPendingCredits(pc);
  }

  function onSelectMembers(data) {
    let cred = [];
    data.forEach((d) => {
      let user = find(credits, (c) => {
        return d === c.userId;
      });

      if (!user) {
        user = find(props.team, (c) => {
          return d === c.userId;
        });
      }
      if (user) {
        user.credits = user.credits ? user.credits : 3;
        cred.push(user);
      }
    });
    setCredits(cred);
    props.togglePrompt();
  }

  function onAddCreditType(val, id, type) {
    let cred = Object.assign([], credits);
    let user = find(cred, (c) => {
      return id === c.userId;
    });
    let idx = findIndex(cred, (c) => {
      return id === c.userId;
    });
    let typeValue = -type;
    if (val) {
      typeValue = type;
    }
    if (user.credits && user.credits > 0) {
      user.credits += typeValue;
    } else {
      user.credits = typeValue;
    }

    cred[idx] = user;
    setCredits(cred);
  }

  return (
    <div className="wizard-content info">
      <h2>{t("Enter information")}</h2>
      <div className="inputs">
        <div className="input">
          <label htmlFor="title">{t("Title")}</label>
          <input
            type="text"
            name="title"
            id="title"
            onChange={(e) => {
              setTitle(e.currentTarget.value);
            }}
            value={title}
          />
          <Tooltip text={t("The song title")} />
        </div>
        <div className="artist-input">
          <div className="input">
            <label htmlFor="artistName">{t("Artist name")}</label>
            <input
              type="text"
              name="artistName"
              id="artistName"
              onChange={(e) => {
                setArtistName(e.currentTarget.value);
              }}
              value={artistName}
              readOnly={demo}
            />
            <Tooltip
              text={t(
                "The artist name. Leave blank if the artist only appears as demo vocals",
              )}
            />
          </div>
          <div className="input">
            <label htmlFor="artistGender">{t("Artist gender")}</label>
            <select
              value={artistGender}
              onChange={(e) => {
                setArtistGender(e.currentTarget.value);
              }}
              readOnly={demo}
            >
              <option value="">{t("Select")}...</option>
              <option value="f">{t("Female")}</option>
              <option value="m">{t("Male")}</option>
              <option value="o">{t("Female & Male")}</option>
            </select>
            <Tooltip
              text={t(
                "The artist's gender. SVT aims to have a gender balanced competition",
              )}
            />
          </div>
          <div className="input labelcheck">
            <label />
            <div>
              <Check
                onClick={(checked) => {
                  setDemo(checked);
                }}
                checked={demo}
                label={t("The artist is only on this demo recording")}
              />
              
            </div>
          </div>
        </div>

        <div className="input">
          <label htmlFor="credits">{t("Credits")}</label>
          <Credits
            onAddTeamMember={onAddTeamMember}
            onSelectMembers={onSelectMembers}
            onAddCreditType={onAddCreditType}
            team={props.team}
            togglePrompt={props.togglePrompt}
            data={credits}
          />
        </div>
        <div className="input">
          <label htmlFor="genre">{t("Genre")}</label>
          <select
            name="genre"
            value={genre}
            onChange={(e) => {
              setGenre(e.currentTarget.value);
            }}
          >
            <option value={0}>{t("Select")}...</option>
            {Object.keys(GENRES).map((g) => {
              const m = t(GENRES[g]);
              return (
                <option key={g} value={g}>
                  {m}
                </option>
              );
            })}
          </select>
          <Tooltip
            text={t("Pick one genre, even if your song transcends genres")}
          />
        </div>
        <div className="input">
          <label htmlFor="language">{t("Language")}</label>
          <select
            name="language"
            value={language}
            onChange={(e) => {
              setLanguage(e.currentTarget.value);
            }}
          >
            <option value={0}>{t("Select")}...</option>
            <option value="sv">{t("Swedish")}</option>
            <option value="en">{t("English")}</option>
            <option value="oo">{t("Other")}</option>
          </select>
          <Tooltip
            text={t(
              "Pick one language, even if your song contains several languages",
            )}
          />
        </div>
        <div className="input">
          <label htmlFor="comment">
            {t("Optional comment, biography, etc")}
          </label>
          <textarea
            name="comment"
            value={comment}
            onChange={(e) => {
              setComment(e.currentTarget.value);
            }}
          />
          <Tooltip text={t("Short comment or info about the submission")} />
        </div>
      </div>
      <div className="controls">
        <button className="inverse" onClick={() => navigate(-1)}>
          <i className="icon-left-open" />
          {t("Back")}
        </button>
        <button onClick={onSave} disabled={!done}>
          {t("Next")}
        </button>
      </div>
    </div>
  );
};

export default InfoView;
