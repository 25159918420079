import React from 'react';
import { useTranslation } from 'react-i18next';

import Header from '../global/header/Header';

const Splash = (props) => {
	const { t } = useTranslation();

	const onClickLogin = () => {
		props.router.navigate('/signin/');
	};

	return (
		<div className="splash">
			<Header closed={true} />
			<div className="centered center">
				<h1>
					{t('Welcome to')}
					<br />
					{t('Melodifestivalen 2025')}
				</h1>
				<p>
					{t("Are you the winner of next year's competition?")}
					<br />
					{t("Don't hesitate, submit your song today.")}
					<br />
					{t("Let's go!")}
				</p>
				<div className="closed">
					<h3>{t('Submissions are closed for 2025')}</h3>
					{t('If you have any questions, contact')} <a href="mailto:mellobidrag@svt.se">mellobidrag@svt.se</a>
				</div>
			</div>
		</div>
	);
};

export default Splash;
