import React, { Component } from "react";
import Dropzone from "react-dropzone";
import { Trans } from "react-i18next";

import AudioPlayer from "../global/AudioPlayer";

export default class DropArea extends Component {
  state = {
    uploading: false,
    progress: 0,
    uploadingFile: null,
    file: this.props.submission[this.props.type + "FileUri"],
    fileName: this.props.submission[this.props.type + "FileName"],
  };

  onProgress = this.onProgress.bind(this);

  onDrop = this.onDrop.bind(this);

  static getDerivedStateFromProps(nextProps, currentState) {
    if (
      currentState.uploading ||
      nextProps.submission[nextProps.type + "FileUri"] !== currentState.file ||
      nextProps.submission[nextProps.type + "FileName"] !==
        currentState.fileName
    ) {
      return {
        file: nextProps.submission[nextProps.type + "FileUri"],
        fileName: nextProps.submission[nextProps.type + "FileName"],
      };
    }
    return null;
  }

  onProgress(e) {
    this.setState({
      progress: Math.round((e.loaded / e.total) * 100),
    });
  }

  onDrop(files) {
    this.props.onUpload(files[0], this.props.type, this.onProgress);
    this.setState({
      uploadingFile: files[0].name,
      uploading: true,
      file: null,
      fileName: "",
    });
  }

  render() {
    const { mime, type } = this.props;
    const { file, fileName } = this.state;
    let boxCls = "";
    if (this.state.uploading && !file) {
      boxCls = " uploading";
    } else if (file) {
      boxCls = " uploaded";
    }

    return (
      <div className="drop">
        <Dropzone accept={mime} onDropAccepted={this.onDrop}>
          {({
            getRootProps,
            getInputProps,
            isDragActive,
            isDragAccept,
            isDragReject,
          }) => {
            let cls = "";
            let label = (
              <>
                <Trans>Drag and drop your</Trans> <Trans>{type} </Trans>
                <Trans>file here, or click to select file</Trans>
              </>
            );
            if (isDragAccept) {
              cls = " accept";
              label = <Trans>Drop the file to upload</Trans>;
            }
            if (isDragReject) {
              cls = " reject";
              label = (
                <>
                  <Trans>Please drag and drop an</Trans> <Trans>{type} </Trans>
                  <Trans>file</Trans>
                </>
              );
            }
            return (
              <section className={"dropzone-box" + boxCls}>
                <div className={"dropzone-droparea" + cls} {...getRootProps()}>
                  <input {...getInputProps()} />
                  {file && (
                    <div className="uploaded-file">
                      {type === "image" && (
                        <div className="uploaded-image">
                          <figure style={{ backgroundImage: `url(${file})` }} />
                        </div>
                      )}
                      {type === "audio" && (
                        <div className="uploaded-audio">
                          <AudioPlayer file={file} />
                        </div>
                      )}
                      <div className="filename">{fileName}</div>
                    </div>
                  )}
                  {!file && !this.state.uploading && <p>{label}</p>}
                  {!file && this.state.uploading && (
                    <div className="filename">{this.state.uploadingFile}</div>
                  )}
                </div>
                <div className="upload-progress">
                  <div
                    className="fill"
                    style={{ width: `${this.state.progress}%` }}
                  />
                </div>
              </section>
            );
          }}
        </Dropzone>
      </div>
    );
  }
}
