import find from "lodash/find";

import { INVITE_MEMBER, ADD_MEMBER, GET_TEAM } from "./teamActions";

const initialState = {
  members: [],
};

const teamReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MEMBER: {
      let members = Object.assign([], state.members);
      let member = action.data;
      member.userId = member.id;
      members.push(member);
      const ns = Object.assign({}, state, { members });
      return ns;
    }
    case INVITE_MEMBER: {
      let members = Object.assign([], state.members);
      members.push(action.data);
      const ns = Object.assign({}, state, { members });
      return ns;
    }
    case GET_TEAM: {
      const ns = Object.assign([], state, { members: action.data });
      action.data.forEach((m) => {
        if (
          !find(ns.members, (mm) => {
            return m.userId === mm.userId;
          })
        ) {
          ns.members.push(m);
        }
      });
      return ns;
    }
    default: {
      return state;
    }
  }
};

export default teamReducer;
