import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { showError } from "../../data/app/appActions";

const ErrorPrompt = (props) => {
  const { t } = useTranslation();
  let errorString = "";

  switch (props.error) {
    case "TOO_MANY_REQUESTS": {
      errorString = t(
        "Too many failed logins. Wait a few minutes then try again.",
      );
      break;
    }
    case "EMAIL_VERIFICATION_ERROR": {
      errorString = t(
        "Wrong verification code. Please double check the code and try again.",
      );
      break;
    }
    default: {
      errorString = "";
    }
  }

  if (!props.error) {
    return null;
  }

  return (
    <div className="error-prompt">
      <div className="error-content">
        <h4>Fel</h4>
        <div className="message">{errorString}</div>
        <button className="micro" onClick={props.closeError}>
          Stäng
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    error: state.app.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeError: () => dispatch(showError(null)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPrompt);
