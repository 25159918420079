import { connect } from "react-redux";
import withRouter from "../withRouter";
import HeaderView from "./HeaderView";

import { logOut } from "../../../data/user/userActions";

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user.user && state.user.user.userId ? state.user.user : null,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logOut: () => dispatch(logOut()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderView),
);
