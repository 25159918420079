import { TOGGLE_PROMPT, TOGGLE_LOADING, ERROR, ON_TERMS } from "./appActions";
import { ON_VETO_USER } from "../user/userActions";

const initialState = {
  prompt: null,
  loading: false,
  refreshed: false,
  error: null,
  terms: null,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_PROMPT: {
      let ns = Object.assign({}, state, { prompt: null });
      if (action.component) {
        ns.prompt = action.component;
      }
      return ns;
    }
    case TOGGLE_LOADING: {
      let ns = Object.assign({}, state, { loading: action.data });
      return ns;
    }
    case ON_VETO_USER: {
      let ns = Object.assign({}, state, { refreshed: true });
      return ns;
    }
    case ERROR: {
      let ns = Object.assign({}, state, { error: action.data, loading: false });
      console.log("ERROR", ns);
      return ns;
    }
    case ON_TERMS: {
      return { ...state, terms: action.data };
    }
    default: {
      return state;
    }
  }
};

export default appReducer;
