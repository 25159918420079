import {
  GENRES,
  START,
  UPLOAD,
  INFO,
  SUBMIT,
  RESET_SUBMISSION,
  SELECT_SUBMISSION,
  RESET_FILES,
} from "./submissionActions";
import { ADD_MEMBER } from "../team/teamActions";

const initialState = {
  step: 0,
  id: null,
  audioFileName: null,
  audioFileUri: null,
  imageFileName: null,
  imageFileUri: null,
  title: null,
  artist: null,
  credits: null,
  comment: null,
  genre: null,
  language: null,
  genres: null,
  demo: false,
};

const submissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GENRES: {
      let ns = Object.assign({}, state, { genres: action.data });
      return ns;
    }

    case START: {
      let ns = Object.assign({}, state, { id: action.data.id, step: 1 });
      return ns;
    }

    case UPLOAD: {
      let ns = Object.assign({}, state);
      if (action.data.type === "image") {
        ns = Object.assign({}, state, {
          imageFileName: action.data.imageFileName,
          imageFileUri: action.data.imageFileUri,
        });
      } else {
        ns = Object.assign({}, state, {
          audioFileName: action.data.audioFileName,
          audioFileUri: action.data.audioFileUri,
        });
      }
      ns.step = state.step <= 2 ? action.data.step : state.step;
      return ns;
    }

    case INFO: {
      const d = action.data;
      let ns = Object.assign({}, state, {
        title: d.title,
        artist: d.artist,
        genre: d.genre,
        language: d.language,
        step: state.step < 3 ? 3 : state.step,
        demo: d.demo !== 0,
        credits: JSON.parse(d.credits),
        comment: d.comment,
      });
      return ns;
    }

    case SUBMIT: {
      let ns = Object.assign({}, state, { step: 10 });
      return ns;
    }

    case RESET_SUBMISSION: {
      return Object.assign({}, initialState);
    }

    case SELECT_SUBMISSION: {
      const d = action.data;
      let ns = Object.assign({}, state, { id: d.id });
      Object.keys(d).forEach((dd) => {
        ns[dd] = d[dd];
      });
      try {
        ns.credits = JSON.parse(d.credits);
      } catch (e) {
        ns.credits = d.credits || [];
      }
      return ns;
    }

    case RESET_FILES: {
      let ns = Object.assign({}, state);
      if (action.data.type === "image") {
        ns = Object.assign({}, state, {
          imageFileName: null,
          imageFileUri: null,
        });
      } else {
        ns = Object.assign({}, state, {
          audioFileName: null,
          audioFileUri: null,
        });
      }
      return ns;
    }

    case ADD_MEMBER: {
      const d = action.data;
      let credits = Object.assign([], state.credits);
      credits.push(d);
      let ns = Object.assign({}, state, { credits });
      return ns;
    }

    default: {
      return state;
    }
  }
};

export default submissionReducer;
