import React from 'react';
import { Route, Routes } from 'react-router-dom';
import withRouter from '../global/withRouter';
import { CookiesProvider } from 'react-cookie';

import SignIn from './SignIn';
import EnterCode from './EnterCode';
import Details from './Details';
import SignInWithCode from './SignInWithCode';
import RegisterWithCode from './RegisterWithCode';
import VerificationRequested from './VerificationRequested';

const SignInIndex = (props) => {
	return (
		<CookiesProvider>
			<Routes>
				<Route key="signin_code" path="code" element={<EnterCode />} />
				<Route key="signin_details" path="/details/" element={<Details />} />
				<Route key="signin_verify" path="/verify/" element={<VerificationRequested />} />
				<Route key="signin" path="/" exact element={<SignIn />} />
				<Route key="invited_code" path="/invited/:code?" element={<SignIn />} />
				<Route key="logga-in_code" path="/logga-in/:code" element={<SignInWithCode />} />
				<Route key="registrera_code" path="/registrera/:code" element={<RegisterWithCode />} />
			</Routes>
		</CookiesProvider>
	);
};

export default withRouter(SignInIndex);
