/*	eslint import/no-mutable-exports: 0 */

/*	@TODO
	fix the cause of eslint warning
*/
let globalStore;

export const createGlobalStore = (store) => {
  globalStore = store;
};

export { globalStore };
