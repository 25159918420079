import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import TeamMember from "./TeamMember";
import Check from "../global/Check";

const TeamView = (props) => {
  const { t } = useTranslation();
  const { user } = props;
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [previousContestant, setPreviousContestant] = useState(0);
  const [expanded, setExpanded] = useState(null);

  function onAddMember() {
    props.addTeamMember({
      name,
      gender,
      previousContestant,
      email,
    });
  }

  function onSetExpanded(id) {
    if (expanded === id) {
      return setExpanded(null);
    }
    return setExpanded(id);
  }

  useEffect(() => {
    props.getTeam();
  }, []);

  useEffect(() => {
    setEmail("");
    setName("");
    setGender("");
    setPreviousContestant(0);
    setExpanded(null);
  }, [props.members]);

  let els = [];
  props.members.forEach((m) => {
    if (m.userId !== user.userId && m.name) {
      els.push(
        <TeamMember
          inviteTeamMember={props.inviteTeamMember}
          setExpanded={onSetExpanded}
          expanded={expanded === m.userId}
          key={m.userId}
          data={m}
        />,
      );
    }
  });

  return (
    <div className="page team">
      <div className="page-content">
        <div className="team-list boxed">
          <h2>{t("Your team")}</h2>
          <div className="members">{els}</div>
        </div>
        <div className="team-invite">
          <h2>
            {t("Invite the authors and composers of your song submissions")}
          </h2>
          <p>
            {t(
              "Add your partners here so they can follow the review process of your song",
            )}
          </p>
          <div className="add-account">
            <div className="credits-form">
              <div className="credits-item name">{t("Name")}</div>
              <div className="credits-item gender">{t("Gender")}</div>
            </div>
            <div className="credits-form">
              <div className="credits-item name">
                <div className="credits-item-wrapper">
                  <input
                    type="text"
                    name="name"
                    value={name}
                    onChange={(e) => {
                      setName(e.currentTarget.value);
                    }}
                  />
                </div>
              </div>
              <div className="credits-item gender">
                <div className="credits-item-wrapper">
                  <select
                    value={gender}
                    onChange={(e) => {
                      setGender(e.currentTarget.value);
                    }}
                  >
                    <option value="">{t("Select")}...</option>
                    <option value="f">{t("Female")}</option>
                    <option value="m">{t("Male")}</option>
                    <option value="o">{t("Other")}</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="credits-form">
              <div className="credits-item previous">
                <Check
                  onClick={(val) => {
                    setPreviousContestant(val);
                  }}
                  label={t("The author has had songs in previous competitions")}
                  checked={previousContestant}
                />
              </div>
            </div>

            <div className="credits-form">
              <div className="credits-item name">{t("E-mail address")}</div>
            </div>
            <div className="credits-form">
              <div className="credits-item name">
                <div className="credits-item-wrapper">
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.currentTarget.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="input submit">
              <button className="small" onClick={onAddMember}>
                {t("Invite")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamView;
