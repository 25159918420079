/* eslint no-control-regex: 0 */

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Header from "../global/header/Header";

const EnterCodeView = (props) => {
  const { t } = useTranslation();
  const [code, setCode] = useState("");
  const [done, setDone] = useState(false);

  function onSubmit(e) {
    if (done) {
      e.preventDefault();
      props.sendCode({
        code,
      });
    }
  }

  function restart() {
    props.logOut();
    props.router.navigate(-1);
  }

  useEffect(() => {
    const pattern = /(?:[0-9]{6})/;
    if (code.match(pattern)) {
      setDone(true);
    } else {
      setDone(false);
    }
  }, [code]);
  if (props.redirect) {
    return null;
  }
  return (
    <div className="splash signin">
      <Header />
      <div className="centered static-width">
        <h1>{t("Sign in")}</h1>
        <p>
          {t(
            "We sent an email message to you. Please enter the code from the email here",
          )}
        </p>
        <form className="sign-in" onSubmit={onSubmit}>
          <div className="input">
            <label htmlFor="code">{t("Code from e-mail")}</label>
            <input
              type="text"
              name="code"
              onChange={(e) => {
                setCode(e.currentTarget.value.replace(/[^0-9]/gi, ""));
              }}
              value={code}
            />
          </div>
          <div className="controls">
            <button type="reset" onClick={restart}>
              {t("Back")}
            </button>
            <button
              type="submit"
              onClick={onSubmit}
              disabled={done ? false : true}
            >
              {t("Sign in")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EnterCodeView;
