import React from 'react';
import { Route, Routes, useRoutes } from 'react-router-dom';

import StartView from './StartView';
import UploadView from './UploadView';
import InfoView from './InfoView';
import PreviewView from './PreviewView';
import SubmittedView from './SubmittedView';

import Breadcrumbs from './Breadcrumbs';

const SubmissionView = (props) => {
	console.log('SubmissionView', props);
	return (
		<div className="page submission">
			<Breadcrumbs {...props} />
			<div className="wizard">
				<Routes>
					<Route key="sub" exact path="/" element={<StartView {...props} />} />
					<Route key="upload" path="/upload" element={<UploadView {...props} />} />
					<Route key="info" path="/info" element={<InfoView {...props} />} />
					<Route key="preview" path="/preview" element={<PreviewView {...props} />} />
					<Route key="subdone" path="/done" element={<SubmittedView {...props} />} />
				</Routes>
			</div>
		</div>
	);
};

export default SubmissionView;
