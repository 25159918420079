import React, { useEffect } from "react";
import { connect } from "react-redux";
import withRouter from "./withRouter";

export default (ComposedComponent) => {
  const Authenticate = (props) => {
    useEffect(() => {
      checkAndRedirect();
    }, []);

    const checkAndRedirect = () => {
      const { isAuthenticated, location } = props;
      if (!isAuthenticated && location.pathname.indexOf("signin") < 0) {
        props.router.navigate("/signin/");
      }
    };

    return (
      <>{props.isAuthenticated ? <ComposedComponent {...props} /> : null}</>
    );
  };

  const mapStateToProps = (state) => {
    const user = state.user.user;
    let verified = false;
    user.accounts.forEach((a) => {
      if (a.verified) {
        verified = true;
      }
    });
    return {
      isAuthenticated: user && user.userId && user.accessToken && verified,
    };
  };

  const mapDispatchToProps = () => {
    return {};
  };

  return withRouter(connect(mapStateToProps, mapDispatchToProps)(Authenticate));
};
