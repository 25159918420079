import React, { Component } from "react";

import { Trans } from "react-i18next";

import DropArea from "./DropArea";

class UploadView extends Component {
  state = {
    uploading: false,
    progress: 0,
    uploaded: false,
    tempImage: null,
  };

  setDefaultCover = this.setDefaultCover.bind(this);

  static getDerivedStateFromProps(nextProps) {
    if (
      nextProps.submission.audioFileUri &&
      nextProps.submission.imageFileUri
    ) {
      return {
        uploaded: true,
      };
    }
    return false;
  }

  /* componentDidMount() {
		setTimeout(() => {
			this.setState({ uploading: true });
		}, 1000);
	} */

  setDefaultCover() {
    this.props.setDefaultImage();
  }

  render() {
    return (
      <div className="wizard-content">
        <div className="upload-input">
          <h2>
            <Trans>Upload song</Trans>
          </h2>

          <DropArea
            type="audio"
            submission={this.props.submission}
            mime={["audio/*"]}
            onUpload={this.props.onUpload}
          />
          <div className="description">
            <h4>
              <Trans>Specifications</Trans>
            </h4>
            <ul>
              <li>
                <Trans>MP3 file format</Trans>
              </li>
            </ul>
          </div>
        </div>
        <div className="upload-input">
          <h2>
            <Trans>Upload image</Trans>
          </h2>
          <DropArea
            type="image"
            submission={this.props.submission}
            mime={["image/jpeg", "image/png"]}
            onUpload={this.props.onUpload}
            tempImage={this.state.tempImage}
          />
          <div className="description">
            <Trans>
              <h4>
                <Trans>Specifications</Trans>
              </h4>
              <ul>
                <li>
                  <Trans>JPG or PNG file format</Trans>
                </li>
                <li>
                  <Trans>Square image format</Trans>
                </li>
                <li>
                  <Trans>Minimum 1000px by 1000px</Trans>
                </li>
                <li onClick={this.setDefaultCover} className="text-link">
                  <Trans>
                    If the artist only appears as demo vocals, click here
                  </Trans>
                </li>
              </ul>
            </Trans>
          </div>
        </div>
        <div className="controls">
          {/*<button className="inverse" onClick={() => this.props.history.go(-1)}>
						<Trans>Back</Trans>
					</button>*/}
          <button
            disabled={!this.state.uploaded}
            onClick={() => this.props.goNext(this.props.location.pathname)}
          >
            <Trans>Next</Trans>
          </button>
        </div>
      </div>
    );
  }
}

export default UploadView;
