/* eslint no-control-regex: 0 */

import React from "react";
import { useTranslation } from "react-i18next";

import Header from "../global/header/Header";

const VerificationRequested = (props) => {
  const { t } = useTranslation();
  if (props.redirect) {
    return null;
  }
  return (
    <div className="splash signin">
      <Header />
      <div className="centered static-width">
        <h1>{t("Sign in")}</h1>
        <p>
          {t(
            "We sent an email message to you. Please click on the link in the email.",
          )}
        </p>
      </div>
    </div>
  );
};

export default VerificationRequested;
